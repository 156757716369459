import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AnnuaryDepartmentDetailsType = {
  __typename?: 'AnnuaryDepartmentDetailsType';
  /** Id ФОИВ-а */
  departmentId: Scalars['Int']['output'];
  /** Финансовая дисциплина - Принятые БО */
  effFinanceAcceptedCommitments: Scalars['Float']['output'];
  /** Финансовая дисциплина - Касса */
  effFinanceCash: Scalars['Float']['output'];
  /** Финансовая дисциплина - F id */
  effFinanceId: Scalars['Int']['output'];
  /** Финансовая дисциплина - Остаток */
  effFinanceRemain: Scalars['Float']['output'];
  /** Эффективность ВПЦТ - Финансовая дисциплина - F */
  effFinanceValue: Scalars['Float']['output'];
  /** Финансовая дисциплина - ВПЦТ */
  effFinanceVpct: Scalars['Float']['output'];
  /** ГосТех - T id */
  effGovTechId: Scalars['Int']['output'];
  /** ЭГосТех - флаг успеха */
  effGovTechReached: Scalars['Boolean']['output'];
  /** Эффективность ВПЦТ - ГосТех - T */
  effGovTechValue: Scalars['Float']['output'];
  /** ГосТех - год внедрения */
  effGovTechYear?: Maybe<Scalars['Float']['output']>;
  /** Ключевые показатели - D id */
  effIndicatorsId: Scalars['Int']['output'];
  /** Ключевые показатели - достигнуто */
  effIndicatorsReached: Scalars['Float']['output'];
  /** Ключевые показатели - всего */
  effIndicatorsTotal: Scalars['Float']['output'];
  /** Ключевые показатели - не достигнуто */
  effIndicatorsUnreached: Scalars['Float']['output'];
  /** Эффективность ВПЦТ - Ключевые показатели - D */
  effIndicatorsValue: Scalars['Float']['output'];
  /** ключ кеширования */
  id: Scalars['String']['output'];
  /** Опер.рейтинг - Q id */
  opRatingId: Scalars['Int']['output'];
  /** Опер.рейтинг - Q показатели */
  opRatingIndicators: Array<AnnuaryDepartmentRatingIndicatorType>;
  /** Опер.рейтинг - значение Q */
  opRatingValue: Scalars['Float']['output'];
};


export type AnnuaryDepartmentDetailsTypeOpRatingIndicatorsArgs = {
  indicatorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum AnnuaryDepartmentEfficiencyGroupEnum {
  Average = 'Average',
  High = 'High',
  Low = 'Low'
}

export type AnnuaryDepartmentIndicatorsType = {
  __typename?: 'AnnuaryDepartmentIndicatorsType';
  /** id ФОИВ-а */
  departmentId: Scalars['Int']['output'];
  /** Ключ кеширования */
  id: Scalars['String']['output'];
  /** Оперативный рейтинг (Q) */
  operationalRating: Scalars['Float']['output'];
  /** Качество планирования ВПЦТ: - общее (R) */
  planningQuality: Scalars['Float']['output'];
  /** Эффективность ВПЦТ: - общая (E) */
  vpctEfficiency: Scalars['Float']['output'];
  /** Эффективность ВПЦТ: - финансовая дисциплина (F) */
  vpctFinance: Scalars['Float']['output'];
  /** Эффективность ВПЦТ: - гостех (T) */
  vpctGovTech: Scalars['Float']['output'];
  /** Эффективность ВПЦТ: - ключевые показатели (D) */
  vpctIndicators: Scalars['Float']['output'];
};

export type AnnuaryDepartmentRatingIndicatorType = {
  __typename?: 'AnnuaryDepartmentRatingIndicatorType';
  /** компоненты показателя рейтинга */
  components: Array<AnnuaryIndicatorComponentType>;
  /** значение по показателю за текущий период */
  currentValue: Scalars['Float']['output'];
  /** id ФОИВ-а */
  departmentId: Scalars['Int']['output'];
  /** динамика показателя по периодам */
  dynamicChart: Array<AnnuaryIndicatorDynamicChartPointType>;
  /** ключ кеширования */
  id: Scalars['String']['output'];
  /** id показателя */
  indicatorId: Scalars['Int']['output'];
  /** максимально возможное значение по показателю */
  maxValue: Scalars['Float']['output'];
  /** название показателя */
  name: Scalars['String']['output'];
  /** id периода данных по показателю */
  periodId: Scalars['Int']['output'];
  /** ответственное лицо */
  responsible?: Maybe<AnnuaryIndicatorResponsibleType>;
  /** Частота обновления данных */
  updateFrequency?: Maybe<Scalars['String']['output']>;
  /** Версия показателя рейтинга */
  version: Scalars['Int']['output'];
};

export type AnnuaryDepartmentRatingType = {
  __typename?: 'AnnuaryDepartmentRatingType';
  departments: Array<AnnuaryDepartmentType>;
  /** Ключ кеширования */
  id: Scalars['String']['output'];
};


export type AnnuaryDepartmentRatingTypeDepartmentsArgs = {
  departmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AnnuaryDepartmentType = {
  __typename?: 'AnnuaryDepartmentType';
  /** Год назначения ФРЦТ / Вице-премьера */
  appointmentYear?: Maybe<Scalars['String']['output']>;
  /** id ФОИВ-а */
  departmentId: Scalars['Int']['output'];
  /** название ФОИВ-а */
  departmentName: Scalars['String']['output'];
  /** Дополнительная информация по ФОИВ-у */
  details: AnnuaryDepartmentDetailsType;
  /** динамика эффективности относительно предыдущего года */
  dynamic?: Maybe<Scalars['Float']['output']>;
  /** График динамики значений */
  dynamicChart: Array<AnnuaryDynamicChartPoint>;
  /** Итоговая оценка - динамика, -1 год */
  eff1ybDynamic?: Maybe<Scalars['Float']['output']>;
  /** Итоговая оценка - значение, -1 год */
  eff1ybValue?: Maybe<Scalars['Float']['output']>;
  /** Итоговая оценка - динамика, -2 года */
  eff2ybDynamic?: Maybe<Scalars['Float']['output']>;
  /** Итоговая оценка - значение, -2 года */
  eff2ybValue?: Maybe<Scalars['Float']['output']>;
  /** Итоговая оценка - динамика, -3 года */
  eff3ybDynamic?: Maybe<Scalars['Float']['output']>;
  /** Итоговая оценка - значение, -3 года */
  eff3ybValue?: Maybe<Scalars['Float']['output']>;
  /** Итоговая оценка - динамика, текущий год */
  effCurrentDynamic?: Maybe<Scalars['Float']['output']>;
  /** Итоговая оценка - значение, текущий год */
  effCurrentValue: Scalars['Float']['output'];
  /** Максимальное значение в итоговой оценке эффективности */
  effMaxValue: Scalars['Float']['output'];
  /** ключ кеширования */
  id: Scalars['String']['output'];
  /** Показатели годового рейтинга ФОИВ-а, заполняются только при наличии доступа */
  indicators?: Maybe<AnnuaryDepartmentIndicatorsType>;
  /** флаг, указывающий, что текущий пользователь относится к данному ФОИВх-у */
  isCurrentDepartment: Scalars['Boolean']['output'];
  /** Id периода данных */
  periodId: Scalars['Int']['output'];
  /** группа эффективности в рейтинге */
  ratingGroup: AnnuaryDepartmentEfficiencyGroupEnum;
  /** Место в рейтинге */
  ratingPosition: Scalars['Int']['output'];
  /** Динамика позиции в рейтинге */
  ratingPositionDynamic: Scalars['Int']['output'];
  /** ФРЦТ */
  rct?: Maybe<Scalars['String']['output']>;
  /** Вице-Премьер */
  viceChairman?: Maybe<Scalars['String']['output']>;
  /** Финансирование ВПЦТ */
  vpctSum: Scalars['Float']['output'];
};

export type AnnuaryDynamicChartPoint = {
  __typename?: 'AnnuaryDynamicChartPoint';
  value: Scalars['Float']['output'];
  year: Scalars['Int']['output'];
};

export type AnnuaryIndicatorComponentType = {
  __typename?: 'AnnuaryIndicatorComponentType';
  componentId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type AnnuaryIndicatorDynamicChartPointType = {
  __typename?: 'AnnuaryIndicatorDynamicChartPointType';
  date: Scalars['String']['output'];
  periodId: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type AnnuaryIndicatorResponsibleType = {
  __typename?: 'AnnuaryIndicatorResponsibleType';
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
};

export type AnnuaryIndicatorValueByPeriodType = {
  __typename?: 'AnnuaryIndicatorValueByPeriodType';
  /** дата периода */
  date: Scalars['String']['output'];
  /** Id показателя */
  indicatorId: Scalars['Int']['output'];
  /** название показателя */
  indicatorName: Scalars['String']['output'];
  /** максимальное значение по показателю */
  maxValue: Scalars['Float']['output'];
  /** Id периода */
  periodId: Scalars['Int']['output'];
  /** значение показателя за период */
  value: Scalars['Float']['output'];
};

export type AnnuaryProfileType = {
  __typename?: 'AnnuaryProfileType';
  /** Доступ к детализации ФРЦТ */
  availableForDepartmentIndicators: Scalars['Boolean']['output'];
  /** ФРЦТ: id ФОИВ-а, к которому прикреплён пользователь */
  departmentId?: Maybe<Scalars['Int']['output']>;
  /** ФРЦТ: название ФОИВ-а, к которому прикреплён пользователь */
  departmentName?: Maybe<Scalars['String']['output']>;
  /** email пользователя */
  email?: Maybe<Scalars['String']['output']>;
  /** ФИО пользователя */
  fullName: Scalars['String']['output'];
  /** ID пользователя */
  id: Scalars['Int']['output'];
  /** Флаг наблюдателя */
  isObserver: Scalars['Boolean']['output'];
  /** URL для загрузки фотографии пользователя? */
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** РРЦТ: id РОИВ-а, к которому прикреплён пользователь */
  regionId?: Maybe<Scalars['Int']['output']>;
  /** РРЦТ: название РОИВ-а, к которому прикреплён пользователь */
  regionName?: Maybe<Scalars['String']['output']>;
};

export type AnnuaryRegionDetailsType = {
  __typename?: 'AnnuaryRegionDetailsType';
  /** степень достижения */
  degree: Scalars['Float']['output'];
  /** ключ кеширования */
  id: Scalars['String']['output'];
  /** массив показателей опер.рейтинга */
  opRatingIndicators: Array<AnnuaryRegionRatingIndicatorType>;
  /** общее кол-во заработанных баллов */
  pointsAchieved?: Maybe<Scalars['Float']['output']>;
  /** максимально возможное кол-во заработанных баллов */
  pointsMax: Scalars['Float']['output'];
  /** id РОИВ-а */
  regionId: Scalars['Int']['output'];
};


export type AnnuaryRegionDetailsTypeOpRatingIndicatorsArgs = {
  indicatorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum AnnuaryRegionGroupEnum {
  Average = 'Average',
  High = 'High'
}

export type AnnuaryRegionRatingIndicatorType = {
  __typename?: 'AnnuaryRegionRatingIndicatorType';
  /** компоненты показателя рейтинга */
  components: Array<AnnuaryIndicatorComponentType>;
  /** значение по показателю за текущий период */
  currentValue: Scalars['Float']['output'];
  /** динамика показателя по периодам */
  dynamicChart: Array<AnnuaryIndicatorDynamicChartPointType>;
  /** ключ кеширования */
  id: Scalars['String']['output'];
  /** id показателя */
  indicatorId: Scalars['Int']['output'];
  /** максимально возможное значение по показателю */
  maxValue: Scalars['Float']['output'];
  /** название показателя */
  name: Scalars['String']['output'];
  /** id периода данных по показателю */
  periodId: Scalars['Int']['output'];
  /** id РОИВ-а */
  regionId: Scalars['Int']['output'];
  /** ответственное лицо */
  responsible?: Maybe<AnnuaryIndicatorResponsibleType>;
  /** Частота обновления данных */
  updateFrequency?: Maybe<Scalars['String']['output']>;
  /** Версия показателя рейтинга */
  version: Scalars['Int']['output'];
};

export type AnnuaryRegionRatingType = {
  __typename?: 'AnnuaryRegionRatingType';
  /** ключ кеширования */
  id: Scalars['String']['output'];
  regions: Array<AnnuaryRegionType>;
};


export type AnnuaryRegionRatingTypeRegionsArgs = {
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AnnuaryRegionType = {
  __typename?: 'AnnuaryRegionType';
  /** Год назначения РРЦТ / Уполномоченного представителя */
  appointmentYear?: Maybe<Scalars['Int']['output']>;
  /** Дополнительные данные РОИВ-а, в частности по оперативному рейтингу */
  details: AnnuaryRegionDetailsType;
  /** Динамика эффективности относительно прошлого периода */
  dynamic?: Maybe<Scalars['Float']['output']>;
  /** 1 год назад - динамика */
  eff1ybDynamic?: Maybe<Scalars['Float']['output']>;
  /** 1 год назад - значение */
  eff1ybValue?: Maybe<Scalars['Float']['output']>;
  /** 2 года назад - динамика */
  eff2ybDynamic?: Maybe<Scalars['Float']['output']>;
  /** 2 года назад - значение */
  eff2ybValue?: Maybe<Scalars['Float']['output']>;
  /** 3 года назад - динамика */
  eff3ybDynamic?: Maybe<Scalars['Float']['output']>;
  /** 3 года назад - значение */
  eff3ybValue?: Maybe<Scalars['Float']['output']>;
  /** этот год - динамика */
  effCurrentDynamic?: Maybe<Scalars['Float']['output']>;
  /** этот год - значение */
  effCurrentValue: Scalars['Float']['output'];
  /** Итоговая оценка эффективности и результативности */
  effMaxValue: Scalars['Float']['output'];
  /** Федеральный округ */
  federalDistrictName?: Maybe<Scalars['String']['output']>;
  /** Сокращенное федерального округа */
  federalDistrictShortName?: Maybe<Scalars['String']['output']>;
  /** ключ кеширования */
  id: Scalars['String']['output'];
  /** Значения показателей за период для РОИВ-а */
  indicatorValues: Array<AnnuaryIndicatorValueByPeriodType>;
  /** флаг, указывающий, что текущий пользователь относится к данному РОИВ-у */
  isCurrentRegion: Scalars['Boolean']['output'];
  /** id периода данных */
  periodId: Scalars['Int']['output'];
  /** Группа эффективности региона - Лидирующая или Перспективная */
  ratingGroup: AnnuaryRegionGroupEnum;
  /** позиция в рейтинге */
  ratingPosition: Scalars['Int']['output'];
  /** динамика места в рейтинге */
  ratingPositionDynamic: Scalars['Float']['output'];
  /** РРЦТ */
  rct?: Maybe<Scalars['String']['output']>;
  /** id РОИВ-а */
  regionId: Scalars['Int']['output'];
  /** название региона */
  regionName: Scalars['String']['output'];
  /** Код РОИВ-а */
  regionShortCode: Scalars['String']['output'];
  /** Уполномоченный представитель */
  representative?: Maybe<Scalars['String']['output']>;
};

export type AnnuaryType = {
  __typename?: 'AnnuaryType';
  /** Годовой рейтинг ФРЦТ */
  departmentRating: AnnuaryDepartmentRatingType;
  /** Ключ для кеширования, учитывает год и данные профиля */
  id: Scalars['String']['output'];
  /** Профиль пользователя Годового рейтинга */
  profile: AnnuaryProfileType;
  /** Годовой рейтинг РРЦТ */
  regionRating: AnnuaryRegionRatingType;
  validationResults: Array<Scalars['String']['output']>;
};

export type AuthGetTokensInput = {
  /** Код авторизации */
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  scope: AuthTokensScope;
};

export type AuthLogoutInput = {
  scope: AuthTokensScope;
  token: Scalars['String']['input'];
};

export type AuthRefreshTokensInput = {
  refreshToken: Scalars['String']['input'];
  scope: AuthTokensScope;
};

export type AuthTokensResponseType = {
  __typename?: 'AuthTokensResponseType';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Int']['output'];
  idToken?: Maybe<Scalars['String']['output']>;
  refreshToken: Scalars['String']['output'];
  tokenType: Scalars['String']['output'];
};

export enum AuthTokensScope {
  Annuary = 'Annuary',
  Dashboard = 'Dashboard',
  Rating = 'Rating'
}

export type AvailableRegionIndicatorType = {
  __typename?: 'AvailableRegionIndicatorType';
  indicatorId: Scalars['Int']['output'];
  indicatorName: Scalars['String']['output'];
  maxValue: Scalars['Float']['output'];
};

export type BudgetSpendingDirectionType = {
  __typename?: 'BudgetSpendingDirectionType';
  /** Ключ кеширования, учитывает: departmentId, directionId, directionName, index */
  _id: Scalars['String']['output'];
  departmentId: Scalars['Int']['output'];
  directionId: Scalars['Int']['output'];
  govPrograms?: Maybe<Array<GovernmentProgramType>>;
  name: Scalars['String']['output'];
  otherSpending: Scalars['Float']['output'];
  plan2xx: Scalars['Float']['output'];
  totalSpending: Scalars['Float']['output'];
};


export type BudgetSpendingDirectionTypeGovProgramsArgs = {
  programCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum CommonDepartmentRequestStatusEnum {
  Approved = 'Approved',
  ChoiceOfBasis = 'ChoiceOfBasis',
  Draft = 'Draft',
  OnAgreementHeadExp = 'OnAgreementHeadExp',
  OnApprovalMc = 'OnApprovalMc',
  OnApprovalRct = 'OnApprovalRct',
  OnCheck = 'OnCheck',
  OnEdit = 'OnEdit',
  Other = 'Other'
}

export type DashboardProfileAvailableDepartmentType = {
  __typename?: 'DashboardProfileAvailableDepartmentType';
  authority: Scalars['Int']['output'];
  departmentFullName: Scalars['String']['output'];
  departmentShortName: Scalars['String']['output'];
};

export type DashboardProfileDepartmentType = {
  __typename?: 'DashboardProfileDepartmentType';
  authority: Scalars['Int']['output'];
  departmentFullName?: Maybe<Scalars['String']['output']>;
  departmentShortName?: Maybe<Scalars['String']['output']>;
  homeUrl?: Maybe<Scalars['String']['output']>;
  /** Ключ кеширования, учитывает authority, departmentFullName */
  id: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
};

export type DashboardProfileType = {
  __typename?: 'DashboardProfileType';
  accessToChangeKeyIndicators: Scalars['Boolean']['output'];
  accessToTagLibraryMaterials: Scalars['Boolean']['output'];
  availableDepartments: Array<DashboardProfileAvailableDepartmentType>;
  department: DashboardProfileDepartmentType;
  email?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  legacy: Scalars['Boolean']['output'];
  photoUrl?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
};

export type DashboardType = {
  __typename?: 'DashboardType';
  authority: Scalars['Int']['output'];
  documents: DocumentsType;
  events: EventsType;
  financialAnalysis: FinancialAnalysisType;
  /** Ключ дла кеширования, учитывает: authority */
  id: Scalars['String']['output'];
  keyIndicators: KeyIndicatorsType;
  keySystems: KeySystemsType;
  library: LibraryType;
  notifications: NotificationsType;
  operationalRating: OperationalRatingType;
  profile: DashboardProfileType;
  stats: StatsType;
  validationResults: Array<Scalars['String']['output']>;
  widgetSettings: Array<WidgetSettingsType>;
};


export type DashboardTypeDocumentsArgs = {
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<DocumentsCommonInput>;
};


export type DashboardTypeEventsArgs = {
  input?: InputMaybe<EventsCommonInput>;
};


export type DashboardTypeFinancialAnalysisArgs = {
  onlyBudget?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DashboardTypeKeyIndicatorsArgs = {
  serviceIndicators?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DataUploadPeriodType = {
  __typename?: 'DataUploadPeriodType';
  available: Scalars['Boolean']['output'];
  dateSubmit: Scalars['String']['output'];
  dateSubmitFormatted: Scalars['String']['output'];
  periodId: Scalars['Int']['output'];
  published: Scalars['Boolean']['output'];
};

export enum DepartmentFeedbackKindEnum {
  Department = 'Department',
  DepartmentIndicator = 'DepartmentIndicator',
  Other = 'Other'
}

export type DepartmentFeedbackType = {
  __typename?: 'DepartmentFeedbackType';
  attachments: Array<RatingFeedbackAttachmentType>;
  commentary: Scalars['String']['output'];
  departmentId: Scalars['Int']['output'];
  feedbackId: Scalars['Int']['output'];
  /** Ключ кеширования, учитывает departmentId, feedbackId, lastPeriodId */
  id: Scalars['String']['output'];
  indicatorId?: Maybe<Scalars['Int']['output']>;
  indicatorName?: Maybe<Scalars['String']['output']>;
  kind: DepartmentFeedbackKindEnum;
  publishDate: Scalars['String']['output'];
  publisher: Scalars['String']['output'];
  subject?: Maybe<Scalars['String']['output']>;
};

export type DepartmentIndicatorType = {
  __typename?: 'DepartmentIndicatorType';
  alertMark: Scalars['Boolean']['output'];
  availableForDetails: Scalars['Boolean']['output'];
  components: Array<IndicatorComponentType>;
  departmentId: Scalars['Int']['output'];
  detalization?: Maybe<IndicatorDetalizationTableType>;
  dynamic?: Maybe<Scalars['Float']['output']>;
  feedback?: Maybe<DepartmentFeedbackType>;
  groupField?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  haveFeedback: Scalars['Boolean']['output'];
  haveRecommendations: Scalars['Boolean']['output'];
  history: Array<IndicatorValueByPeriodType>;
  /** Ключ кеширования */
  id: Scalars['String']['output'];
  indicatorId: Scalars['Int']['output'];
  methodologies: Array<IndicatorMethodologyType>;
  name: Scalars['String']['output'];
  pointsMax: Scalars['Float']['output'];
  pointsTotal: Scalars['Float']['output'];
  pointsTotalPercentage: Scalars['Float']['output'];
  progressGroup: RatingIndicatorProgressGroupEnum;
  recommendations?: Maybe<IndicatorRecommendationsType>;
  responsibles: Array<PrimePersonType>;
  specifications: Array<IndicatorSpecificationType>;
  successMark: Scalars['Boolean']['output'];
  updateFrequency?: Maybe<Scalars['String']['output']>;
  /** Версия показателя рейтинга */
  version: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type DepartmentIndicatorValueType = {
  __typename?: 'DepartmentIndicatorValueType';
  indicatorId: Scalars['Int']['output'];
  indicatorName: Scalars['String']['output'];
  maxScore: Scalars['Float']['output'];
  scoreForLastPeriod?: Maybe<Scalars['Float']['output']>;
  scoreForPreviousPeriod?: Maybe<Scalars['Float']['output']>;
};

export type DepartmentIndicatorsCriteriaType = {
  __typename?: 'DepartmentIndicatorsCriteriaType';
  efficiencyHighMax: Scalars['Float']['output'];
  efficiencyHighMin: Scalars['Float']['output'];
  efficiencyLowMax: Scalars['Float']['output'];
  efficiencyLowMin: Scalars['Float']['output'];
  efficiencyMidMax: Scalars['Float']['output'];
  efficiencyMidMin: Scalars['Float']['output'];
  group1Max: Scalars['Float']['output'];
  group1Min: Scalars['Float']['output'];
  group2Max: Scalars['Float']['output'];
  group2Min: Scalars['Float']['output'];
  group3Max: Scalars['Float']['output'];
  group3Min: Scalars['Float']['output'];
  group4Min: Scalars['Float']['output'];
  periodId: Scalars['Int']['output'];
};

export type DepartmentMviType = {
  __typename?: 'DepartmentMviType';
  averageDegree: Scalars['Float']['output'];
  departmentsMaxShare: Scalars['Float']['output'];
  departmentsMinShare: Scalars['Float']['output'];
  departmentsQuantity: Scalars['Float']['output'];
  dynamic?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pointsAverage: Scalars['Float']['output'];
  pointsMax: Scalars['Float']['output'];
};

export type DepartmentRatingType = {
  __typename?: 'DepartmentRatingType';
  criteria: DepartmentIndicatorsCriteriaType;
  departments: Array<DepartmentType>;
  /** Ключ кеширования, учитывает lastUpdateDate и prevUpdateDate */
  id: Scalars['String']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
};


export type DepartmentRatingTypeDepartmentsArgs = {
  departmentIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type DepartmentRequestType = {
  __typename?: 'DepartmentRequestType';
  createdDate: Scalars['String']['output'];
  departmentId: Scalars['Int']['output'];
  groundsDescriptions: Array<Scalars['String']['output']>;
  groundsNames: Array<Scalars['String']['output']>;
  /** Ключ кеширования, учитывает: departmentId, requestId */
  id: Scalars['String']['output'];
  requestId: Scalars['Int']['output'];
  requestName: Scalars['String']['output'];
  requestNumber: Scalars['String']['output'];
  requestTypeName: Scalars['String']['output'];
  stateUpdatedAt?: Maybe<Scalars['String']['output']>;
  statusCode: CommonDepartmentRequestStatusEnum;
  statusDisplayContent: Scalars['String']['output'];
  viewed: Scalars['Boolean']['output'];
};

export type DepartmentType = {
  __typename?: 'DepartmentType';
  budgetGroupIndex: Scalars['Int']['output'];
  degree: Scalars['Float']['output'];
  departmentId: Scalars['Int']['output'];
  departmentName: Scalars['String']['output'];
  feedback?: Maybe<Array<DepartmentFeedbackType>>;
  frct: Array<PrimePersonType>;
  /** Ключ кеширования */
  id: Scalars['String']['output'];
  indicators: Array<DepartmentIndicatorType>;
  indicatorsValues: Array<DepartmentIndicatorValueType>;
  isCurrentDepartment: Scalars['Boolean']['output'];
  lastPeriodDate?: Maybe<Scalars['String']['output']>;
  lastPeriodId: Scalars['Int']['output'];
  lastSubmitDate?: Maybe<Scalars['String']['output']>;
  overallDynamic?: Maybe<Scalars['Float']['output']>;
  pointsMax: Scalars['Float']['output'];
  pointsTotal: Scalars['Float']['output'];
  previousPeriodDate?: Maybe<Scalars['String']['output']>;
  ratingGroup: RatingGroupEnum;
  recommendations?: Maybe<IndicatorRecommendationsType>;
  viceChairmans: Array<PrimePersonType>;
  vpctSum: Scalars['Float']['output'];
};


export type DepartmentTypeIndicatorsArgs = {
  indicatorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum DetalizationCellType {
  Boolean = 'BOOLEAN',
  Text = 'TEXT'
}

export type DetalizationFileMetadataType = {
  __typename?: 'DetalizationFileMetadataType';
  calculationId: Scalars['String']['output'];
  indicatorTimePeriodId: Scalars['String']['output'];
  vpctPeriod: Scalars['Int']['output'];
};

export enum DetalizationTableViewForm {
  Form1 = 'FORM1'
}

export enum DocumentAttachmentScopeEnum {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing'
}

export type DocumentAttachmentType = {
  __typename?: 'DocumentAttachmentType';
  createdAt?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  fileType: FileTypeEnum;
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  scope: DocumentAttachmentScopeEnum;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DocumentSnapshotType = {
  __typename?: 'DocumentSnapshotType';
  date: Scalars['String']['output'];
  documentName: Scalars['String']['output'];
  status: DocumentStatusEnum;
  statusText?: Maybe<Scalars['String']['output']>;
};

export enum DocumentStatusEnum {
  Agreement = 'Agreement',
  Executed = 'Executed',
  ExecutedBySending = 'ExecutedBySending',
  Expertise = 'Expertise',
  Other = 'Other',
  Queue = 'Queue',
  Signing = 'Signing'
}

export type DocumentType = {
  __typename?: 'DocumentType';
  attachments: Array<DocumentAttachmentType>;
  deadlineDate?: Maybe<Scalars['String']['output']>;
  deadlineRemainingDays?: Maybe<Scalars['Int']['output']>;
  documentId: Scalars['String']['output'];
  documentName: Scalars['String']['output'];
  documentType: DocumentTypeEnum;
  evaluationObjects: Array<Scalars['String']['output']>;
  favorite: Scalars['Boolean']['output'];
  history?: Maybe<Array<DocumentSnapshotType>>;
  /** Ключ кеширования, учитывает: authority, isArchive, documentId, index */
  id: Scalars['String']['output'];
  incomingDate?: Maybe<Scalars['String']['output']>;
  incomingNumber?: Maybe<Scalars['String']['output']>;
  outgoingDate?: Maybe<Scalars['String']['output']>;
  outgoingName: Scalars['String']['output'];
  outgoingNumber?: Maybe<Scalars['String']['output']>;
  status: DocumentStatusEnum;
  statusDate?: Maybe<Scalars['String']['output']>;
  statusText?: Maybe<Scalars['String']['output']>;
  viewed: Scalars['Boolean']['output'];
};

export enum DocumentTypeEnum {
  Addition = 'Addition',
  Concept = 'Concept',
  Other = 'Other',
  RegulatoryAct = 'RegulatoryAct',
  Roadmap = 'Roadmap',
  TechnicalTask = 'TechnicalTask'
}

export type DocumentsCommonInput = {
  filters?: InputMaybe<DocumentsFiltersInput>;
  pagination?: InputMaybe<PaginationInput>;
  withFiles?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DocumentsFiltersInput = {
  documentTypes?: InputMaybe<Array<DocumentTypeEnum>>;
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
  /** documentId */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentsType = {
  __typename?: 'DocumentsType';
  departmentId: Scalars['Int']['output'];
  isArchive: Scalars['Boolean']['output'];
  items: Array<DocumentType>;
  pagination: PaginationType;
  unreadCount: Scalars['Int']['output'];
};

export type EventType = {
  __typename?: 'EventType';
  attachments: Array<FileType>;
  content: Scalars['String']['output'];
  header: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  important: Scalars['Boolean']['output'];
  publishDate: Scalars['String']['output'];
  tags: Array<TagType>;
};

export type EventsCommonInput = {
  filters?: InputMaybe<EventsFiltersInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<EventsSortInput>;
};

export type EventsFiltersInput = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type EventsSortInput = {
  date?: InputMaybe<SortDirectionEnum>;
  header?: InputMaybe<SortDirectionEnum>;
};

export type EventsType = {
  __typename?: 'EventsType';
  entries: Array<EventType>;
  pagination: PaginationType;
  tagsWithCount: Array<TagWithCountType>;
};

export enum ExcelDataSourceEnum {
  Department = 'Department',
  DepartmentsChart = 'DepartmentsChart',
  Region = 'Region',
  RegionsChart = 'RegionsChart'
}

export type FaqQuestionType = {
  __typename?: 'FaqQuestionType';
  answer: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FaqSectionType = {
  __typename?: 'FaqSectionType';
  name: Scalars['String']['output'];
  questions: Array<FaqQuestionType>;
};

export type FaqType = {
  __typename?: 'FaqType';
  /** Ключ кеширования */
  id: Scalars['String']['output'];
  scope: RatingScopeEnum;
  sections: Array<FaqSectionType>;
};

export type FeedbackAnswersType = {
  __typename?: 'FeedbackAnswersType';
  radioGroup?: Maybe<Array<FeedbackRadioGroupItemType>>;
};

export type FeedbackQuestionType = {
  __typename?: 'FeedbackQuestionType';
  answers: FeedbackAnswersType;
  categoryId: Scalars['Int']['output'];
  categoryName: Scalars['String']['output'];
  isMandatory: Scalars['Boolean']['output'];
  questionId: Scalars['Int']['output'];
  questionName: Scalars['String']['output'];
  recordId: Scalars['Int']['output'];
};

export type FeedbackRadioGroupItemType = {
  __typename?: 'FeedbackRadioGroupItemType';
  answerId: Scalars['Int']['output'];
  answerName: Scalars['String']['output'];
  textCommentAvailable: Scalars['Boolean']['output'];
};

export type FeedbackSurveyAnswerResult = {
  additionalText?: InputMaybe<Scalars['String']['input']>;
  answerId?: InputMaybe<Scalars['Int']['input']>;
  questionId: Scalars['Int']['input'];
  recordId: Scalars['Int']['input'];
};

export type FeedbackSurveyResult = {
  collectionId: Scalars['Int']['input'];
  surveyAnswers: Array<FeedbackSurveyAnswerResult>;
};

export type FeedbackSurveyType = {
  __typename?: 'FeedbackSurveyType';
  collectionId: Scalars['Int']['output'];
  questions: Array<FeedbackQuestionType>;
};

export enum FgiskiFileKind {
  Methodology = 'Methodology',
  Specification = 'Specification'
}

export type FgiskiFilePresignedUrlInput = {
  /** id показателя */
  entityId: Scalars['Int']['input'];
  /** индекс файла в массиве */
  fileIndex: Scalars['Int']['input'];
  kind: FgiskiFileKind;
  /** Id периода, требуется только для расшифровок (kind = Specification) */
  periodId?: InputMaybe<Scalars['Int']['input']>;
  /** ФОИВ или РОИВ */
  scope: RatingScopeEnum;
  /** id ФОИВ-а или РОИВ-а, требуется при получении расшифровки */
  scopeId?: InputMaybe<Scalars['Int']['input']>;
};

export type FileType = {
  __typename?: 'FileType';
  createdAt?: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  fileType: FileTypeEnum;
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum FileTypeEnum {
  Bmp = 'BMP',
  Doc = 'DOC',
  Docx = 'DOCX',
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Jpg = 'JPG',
  Other = 'Other',
  Pdf = 'PDF',
  Png = 'PNG',
  Ppt = 'PPT',
  Pptx = 'PPTX',
  Rar = 'RAR',
  Txt = 'TXT',
  WebP = 'WebP',
  Xls = 'XLS',
  Xlsx = 'XLSX',
  Zip = 'ZIP'
}

export type FinancialAnalysisType = {
  __typename?: 'FinancialAnalysisType';
  acceptedBudgetCommitments: Scalars['Float']['output'];
  budget: Scalars['Float']['output'];
  cashExecution: Scalars['Float']['output'];
  departmentId: Scalars['Int']['output'];
  /** Ключ кеширования, учитывает: departmentId */
  id: Scalars['String']['output'];
  outBudget?: Maybe<Scalars['Float']['output']>;
  remainder: Scalars['Float']['output'];
  spendingDirections?: Maybe<Array<BudgetSpendingDirectionType>>;
};


export type FinancialAnalysisTypeSpendingDirectionsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type GovernmentProgramType = {
  __typename?: 'GovernmentProgramType';
  /** Ключ кеширования, учитывает: departmentId, directionId, programCode, programName, index */
  _id: Scalars['String']['output'];
  departmentId: Scalars['Int']['output'];
  directionId: Scalars['Int']['output'];
  govProjects?: Maybe<Array<GovernmentProjectType>>;
  kind: GovernmentProgramTypeEnum;
  name: Scalars['String']['output'];
  otherSpending: Scalars['Float']['output'];
  plan2xx: Scalars['Float']['output'];
  programCode: Scalars['String']['output'];
  totalSpending: Scalars['Float']['output'];
};


export type GovernmentProgramTypeGovProjectsArgs = {
  projectCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum GovernmentProgramTypeEnum {
  GovernmentProgram = 'GovernmentProgram',
  Other = 'Other'
}

export type GovernmentProjectType = {
  __typename?: 'GovernmentProjectType';
  /** Ключ кеширования, учитывает: departmentId, directionId, programCode, projectCode, projectName, index */
  _id: Scalars['String']['output'];
  departmentId: Scalars['Int']['output'];
  directionId: Scalars['Int']['output'];
  kind: GovernmentProjectTypeEnum;
  name: Scalars['String']['output'];
  otherSpending: Scalars['Float']['output'];
  plan2xx: Scalars['Float']['output'];
  programCode: Scalars['String']['output'];
  projectCode: Scalars['String']['output'];
  subProjects?: Maybe<Array<GovernmentSubProjectType>>;
  totalSpending: Scalars['Float']['output'];
};

export enum GovernmentProjectTypeEnum {
  Federal = 'Federal',
  National = 'National',
  Other = 'Other'
}

export type GovernmentSubProjectType = {
  __typename?: 'GovernmentSubProjectType';
  /** Ключ кеширования, учитывает: departmentId, directionId, programCode, projectCode, subprojectCode, subProjectName, index */
  _id: Scalars['String']['output'];
  departmentId: Scalars['Int']['output'];
  directionId: Scalars['Int']['output'];
  kind: GovernmentSubProjectTypeEnum;
  name: Scalars['String']['output'];
  otherSpending: Scalars['Float']['output'];
  plan2xx: Scalars['Float']['output'];
  programCode: Scalars['String']['output'];
  projectCode: Scalars['String']['output'];
  subProjectCode: Scalars['String']['output'];
  totalSpending: Scalars['Float']['output'];
};

export enum GovernmentSubProjectTypeEnum {
  Federal = 'Federal',
  Other = 'Other'
}

export type IndicatorChartPointType = {
  __typename?: 'IndicatorChartPointType';
  date: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type IndicatorComponentType = {
  __typename?: 'IndicatorComponentType';
  componentId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type IndicatorDetalizationCellType = {
  __typename?: 'IndicatorDetalizationCellType';
  detalizationFileMetadata?: Maybe<DetalizationFileMetadataType>;
  parentColumn: Scalars['String']['output'];
  type: DetalizationCellType;
  value: Scalars['String']['output'];
};

export type IndicatorDetalizationInput = {
  calculationId: Scalars['String']['input'];
  indicatorId: Scalars['Int']['input'];
  indicatorTimePeriodId: Scalars['String']['input'];
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  vpctPeriod: Scalars['Int']['input'];
};

export type IndicatorDetalizationRowType = {
  __typename?: 'IndicatorDetalizationRowType';
  cells: Array<IndicatorDetalizationCellType>;
  index: Scalars['Int']['output'];
};

export type IndicatorDetalizationTableType = {
  __typename?: 'IndicatorDetalizationTableType';
  columns: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rows: Array<IndicatorDetalizationRowType>;
  viewForm: DetalizationTableViewForm;
};

export type IndicatorMethodologyType = {
  __typename?: 'IndicatorMethodologyType';
  extension: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  idx: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  scope: RatingScopeEnum;
};

export type IndicatorRecommendationsType = {
  __typename?: 'IndicatorRecommendationsType';
  draft?: Maybe<RatingCommentaryType>;
  entries: Array<RatingCommentaryType>;
  /** Ключ кеширования */
  id: Scalars['String']['output'];
};

export type IndicatorSpecificationType = {
  __typename?: 'IndicatorSpecificationType';
  extension: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  idx: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  periodId: Scalars['Int']['output'];
  scope: RatingScopeEnum;
};

export type IndicatorValueByPeriodType = {
  __typename?: 'IndicatorValueByPeriodType';
  date: Scalars['String']['output'];
  periodId: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export enum KeyIndicatorColorsEnum {
  Green = 'Green',
  None = 'None',
  Red = 'Red'
}

export type KeyIndicatorCuratorType = {
  __typename?: 'KeyIndicatorCuratorType';
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type KeyIndicatorHistoryType = {
  __typename?: 'KeyIndicatorHistoryType';
  approvedFromOgv: Scalars['Boolean']['output'];
  changesDate: Scalars['String']['output'];
  executor: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type KeyIndicatorType = {
  __typename?: 'KeyIndicatorType';
  _id: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  approvedFromMc: Scalars['Boolean']['output'];
  approvedFromOgv: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  color: KeyIndicatorColorsEnum;
  curators: Array<KeyIndicatorCuratorType>;
  departmentId: Scalars['Int']['output'];
  disagreements: Scalars['Boolean']['output'];
  haveNested: Scalars['Boolean']['output'];
  history: Array<KeyIndicatorHistoryType>;
  id: Scalars['Int']['output'];
  isAggregate: Scalars['Boolean']['output'];
  isKeyIndicator: Scalars['Boolean']['output'];
  mcName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nestedIndicators: Array<KeyIndicatorType>;
  ogvName: Scalars['String']['output'];
  valueFor2022?: Maybe<Scalars['String']['output']>;
  valueFor2023?: Maybe<Scalars['String']['output']>;
  valueFor2024?: Maybe<Scalars['String']['output']>;
  valueFor2025?: Maybe<Scalars['String']['output']>;
  valueInfo: Scalars['String']['output'];
};

export enum KeyIndicatorsFilterEnum {
  AchievedOrApproved = 'AchievedOrApproved',
  All = 'All',
  FailedOrDisagreement = 'FailedOrDisagreement'
}

export type KeyIndicatorsType = {
  __typename?: 'KeyIndicatorsType';
  _id: Scalars['String']['output'];
  achievedOrApproved: Scalars['Int']['output'];
  departmentId: Scalars['Int']['output'];
  failedOrDisagreement: Scalars['Int']['output'];
  indicators: Array<KeyIndicatorType>;
  key: Scalars['Int']['output'];
  serviceIndicators: Scalars['Boolean']['output'];
  total: Scalars['Int']['output'];
};


export type KeyIndicatorsTypeIndicatorsArgs = {
  filter?: InputMaybe<KeyIndicatorsFilterEnum>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type KeySystemIndicatorType = {
  __typename?: 'KeySystemIndicatorType';
  /** Ключ кеширования, учитывает: keySystemId, indicatorId */
  id: Scalars['String']['output'];
  indicatorId: Scalars['Int']['output'];
  keySystemId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type KeySystemType = {
  __typename?: 'KeySystemType';
  /** Ключ кеширования, учитывает: keySystemId */
  _id: Scalars['String']['output'];
  budgetDevelopment: Scalars['Float']['output'];
  budgetExploitation: Scalars['Float']['output'];
  budgetTotal: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  keyIndicatorsCount: Scalars['Int']['output'];
  keySystemIndicators?: Maybe<Array<KeySystemIndicatorType>>;
  name: Scalars['String']['output'];
  shareUrl?: Maybe<Scalars['String']['output']>;
};

export type KeySystemsType = {
  __typename?: 'KeySystemsType';
  departmentId: Scalars['Int']['output'];
  /** Ключ кеширования, учитывает: departmentId, year */
  id: Scalars['String']['output'];
  keyCount: Scalars['Int']['output'];
  systems: Array<KeySystemType>;
  totalBudget: Scalars['Float']['output'];
  totalCount: Scalars['Int']['output'];
};


export type KeySystemsTypeSystemsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LibraryMaterialFavoriteInput = {
  favorite: Scalars['Boolean']['input'];
  materialId: Scalars['Int']['input'];
};

export type LibraryMaterialTagsInput = {
  materialId: Scalars['Int']['input'];
  tagIds: Array<Scalars['Int']['input']>;
};

export type LibraryMaterialType = {
  __typename?: 'LibraryMaterialType';
  createdAt: Scalars['String']['output'];
  favorite: Scalars['Boolean']['output'];
  fileName: Scalars['String']['output'];
  fileType: FileTypeEnum;
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  path: Array<Scalars['String']['output']>;
  size: Scalars['Float']['output'];
  tags: Array<TagType>;
  updatedAt: Scalars['String']['output'];
};

export type LibraryType = {
  __typename?: 'LibraryType';
  materials: Array<LibraryMaterialType>;
  tags: Array<TagWithCountType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeDepartmentPosition: KeyIndicatorType;
  logout: Scalars['String']['output'];
  markDocumentAsViewed: DocumentType;
  markRequestAsViewed: DepartmentRequestType;
  publishComment: Scalars['Boolean']['output'];
  sendRatingFeedback: Scalars['Int']['output'];
  sendRatingFeedbackSurveyResult: Scalars['Boolean']['output'];
  updateLibraryMaterialFavorite: Scalars['String']['output'];
  updateLibraryMaterialTags: Scalars['String']['output'];
  updateWidgetSettings: Array<WidgetSettingsType>;
  upsertCommentDraft: Scalars['Boolean']['output'];
};


export type MutationChangeDepartmentPositionArgs = {
  keyIndicatorId: Scalars['Int']['input'];
};


export type MutationLogoutArgs = {
  input: AuthLogoutInput;
};


export type MutationMarkDocumentAsViewedArgs = {
  documentId: Scalars['String']['input'];
};


export type MutationMarkRequestAsViewedArgs = {
  requestId: Scalars['Int']['input'];
};


export type MutationPublishCommentArgs = {
  input: RatingCommentInput;
};


export type MutationSendRatingFeedbackArgs = {
  input: RatingFeedbackFormInput;
};


export type MutationSendRatingFeedbackSurveyResultArgs = {
  input: FeedbackSurveyResult;
};


export type MutationUpdateLibraryMaterialFavoriteArgs = {
  input: LibraryMaterialFavoriteInput;
};


export type MutationUpdateLibraryMaterialTagsArgs = {
  input: LibraryMaterialTagsInput;
};


export type MutationUpdateWidgetSettingsArgs = {
  input: Array<WidgetSettingsInput>;
};


export type MutationUpsertCommentDraftArgs = {
  input: RatingCommentInput;
};

export type NotificationsType = {
  __typename?: 'NotificationsType';
  departmentId: Scalars['Int']['output'];
  /** Ключ кеширования, учитывает: departmentId */
  id: Scalars['String']['output'];
  requests: Array<DepartmentRequestType>;
};

export type OperationalRatingIndicatorType = {
  __typename?: 'OperationalRatingIndicatorType';
  /** Ключ кеширования, учитывает: departmentId, currentPeriodId, indicatorId */
  _id: Scalars['String']['output'];
  calculationMethod?: Maybe<Scalars['String']['output']>;
  chart: Array<IndicatorChartPointType>;
  currentPeriodId: Scalars['Int']['output'];
  currentValue?: Maybe<Scalars['Float']['output']>;
  departmentId: Scalars['Int']['output'];
  dynamic: RatingDynamicEnum;
  groupField?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  informationSystem?: Maybe<Scalars['String']['output']>;
  maxValue?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  previousPeriodId: Scalars['Int']['output'];
  responsibleEmail?: Maybe<Scalars['String']['output']>;
  responsibleName?: Maybe<Scalars['String']['output']>;
  sourceName?: Maybe<Scalars['String']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  updatePeriodicity?: Maybe<Scalars['String']['output']>;
};

export type OperationalRatingType = {
  __typename?: 'OperationalRatingType';
  currentPeriodId: Scalars['Int']['output'];
  departmentId: Scalars['Int']['output'];
  group?: Maybe<RatingGroupEnum>;
  /** Ключ кеширования, учитывает: departmentId, currentPeriodId */
  id: Scalars['String']['output'];
  lastUpdate?: Maybe<Scalars['String']['output']>;
  operationalRatingIndicators: Array<OperationalRatingIndicatorType>;
  overallDynamic: RatingDynamicEnum;
  pointsAchieved?: Maybe<Scalars['Float']['output']>;
  pointsAchievedDynamic: RatingDynamicEnum;
  pointsAchievedPercentage?: Maybe<Scalars['Float']['output']>;
  pointsTotal?: Maybe<Scalars['Float']['output']>;
  pointsTotalDynamic: RatingDynamicEnum;
  pointsTotalPercentage: Scalars['Float']['output'];
  previousPeriodId: Scalars['Int']['output'];
  relativeBarDynamicValue?: Maybe<Scalars['Float']['output']>;
};


export type OperationalRatingTypeOperationalRatingIndicatorsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginationType = {
  __typename?: 'PaginationType';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export enum PrimePersonKindEnum {
  Frct = 'FRCT',
  Rrct = 'RRCT',
  Responsible = 'Responsible',
  ViceChairmanOrRepresentative = 'ViceChairmanOrRepresentative'
}

export type PrimePersonType = {
  __typename?: 'PrimePersonType';
  /** Email, (актуально только для ответственного по показателю */
  email?: Maybe<Scalars['String']['output']>;
  /** Полное ФИО */
  fullName: Scalars['String']['output'];
  /** ключ кеширования */
  id: Scalars['String']['output'];
  /** ФРЦТ, РРЦТ, Вице-премьер/Полномочный представитель, Ответственный по показателю */
  kind: PrimePersonKindEnum;
  /** Имя */
  name: Scalars['String']['output'];
  /** Отчество */
  patronymic?: Maybe<Scalars['String']['output']>;
  /** Фамилия */
  surname: Scalars['String']['output'];
  /** Год назначение (актуален только для ФРЦТ и РРЦТ) */
  year?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  annuary: AnnuaryType;
  dashboard: DashboardType;
  detalizationPresignedUrl: Scalars['String']['output'];
  excelPresignedUrl: Scalars['String']['output'];
  fgiskiFilePresignedUrl: Scalars['String']['output'];
  filePresignedUrl: Scalars['String']['output'];
  getTokens: AuthTokensResponseType;
  rating: RatingType;
  ratingFeedbackFilePresignedUrl: Scalars['String']['output'];
  refreshTokens: AuthTokensResponseType;
};


export type QueryDetalizationPresignedUrlArgs = {
  input: IndicatorDetalizationInput;
};


export type QueryExcelPresignedUrlArgs = {
  dataSource: ExcelDataSourceEnum;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFgiskiFilePresignedUrlArgs = {
  input: FgiskiFilePresignedUrlInput;
};


export type QueryFilePresignedUrlArgs = {
  entityId: Scalars['Int']['input'];
};


export type QueryGetTokensArgs = {
  input: AuthGetTokensInput;
};


export type QueryRatingFeedbackFilePresignedUrlArgs = {
  fileUUID: Scalars['String']['input'];
};


export type QueryRefreshTokensArgs = {
  input: AuthRefreshTokensInput;
};

export type RatingCommentInput = {
  commentId?: InputMaybe<Scalars['Int']['input']>;
  commentText: Scalars['String']['input'];
  periodId?: InputMaybe<Scalars['Int']['input']>;
  scope: RatingCommentScopeEnum;
  scopeId: Scalars['Int']['input'];
  scopeIndicatorId?: InputMaybe<Scalars['Int']['input']>;
};

export enum RatingCommentScopeEnum {
  Department = 'department',
  DepartmentIndicator = 'departmentIndicator',
  Region = 'region',
  RegionIndicator = 'regionIndicator'
}

export type RatingCommentaryType = {
  __typename?: 'RatingCommentaryType';
  commentText: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** ISO TZ */
  publishDate: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
};

export enum RatingDynamicEnum {
  Negative = 'Negative',
  None = 'None',
  Positive = 'Positive'
}

export type RatingFeedbackAttachmentInput = {
  /** Содержимое файла в формате base64 */
  content: Scalars['String']['input'];
  /** Название файла с расширением */
  fileName: Scalars['String']['input'];
  /** MIME-Type */
  mimeType?: InputMaybe<Scalars['String']['input']>;
};

export type RatingFeedbackAttachmentType = {
  __typename?: 'RatingFeedbackAttachmentType';
  fileExtension: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type RatingFeedbackFormInput = {
  /** Массив вложений / прикреплённых файлов */
  attachments?: InputMaybe<Array<RatingFeedbackAttachmentInput>>;
  /** Комментарий в форме обратной связи */
  commentary: Scalars['String']['input'];
  /** (опционально) id показателя */
  indicatorId?: InputMaybe<Scalars['Int']['input']>;
  /** Обратная связь по ФОИВ (department) / показателю ФОИВ (departmentIndicator) / РОИВ (region) / показателю РОИВ (regionIndicator) */
  scope: RatingFeedbackScope;
  /** В зависимости от scope - либо id ФОИВ-а, либо id РОИВ-а */
  scopeId: Scalars['Int']['input'];
  /** (опционально) тема предложения */
  subject?: InputMaybe<Scalars['String']['input']>;
};

export enum RatingFeedbackScope {
  Department = 'department',
  DepartmentIndicator = 'departmentIndicator',
  Region = 'region',
  RegionIndicator = 'regionIndicator'
}

export enum RatingGroupEnum {
  Average = 'Average',
  High = 'High',
  Low = 'Low'
}

export enum RatingIndicatorProgressGroupEnum {
  Progress = 'Progress',
  Regress = 'Regress',
  Stagnation = 'Stagnation'
}

export type RatingProfileType = {
  __typename?: 'RatingProfileType';
  allowedToChangePeriod: Scalars['Boolean']['output'];
  allowedToComment: Scalars['Boolean']['output'];
  allowedToFeedback: Scalars['Boolean']['output'];
  allowedToReadFeedback: Scalars['Boolean']['output'];
  departmentId?: Maybe<Scalars['Int']['output']>;
  departmentName?: Maybe<Scalars['String']['output']>;
  departmentPeriods: Array<DataUploadPeriodType>;
  departmentPublished: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  feedbackSurvey?: Maybe<FeedbackSurveyType>;
  feedbackSurveyAvailable: Scalars['Boolean']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isObserver: Scalars['Boolean']['output'];
  legacy: Scalars['Boolean']['output'];
  photoUrl?: Maybe<Scalars['String']['output']>;
  regionId?: Maybe<Scalars['Int']['output']>;
  regionName?: Maybe<Scalars['String']['output']>;
  regionPeriods: Array<DataUploadPeriodType>;
};

export enum RatingScopeEnum {
  Departments = 'Departments',
  Regions = 'Regions'
}

export type RatingType = {
  __typename?: 'RatingType';
  departmentRating: DepartmentRatingType;
  departmentsFaq: FaqType;
  departmentsMvi: Array<DepartmentMviType>;
  /** @deprecated not used */
  description: Scalars['String']['output'];
  /** Ключ кеширования */
  id: Scalars['String']['output'];
  profile: RatingProfileType;
  regionRating: RegionRatingType;
  regionsFaq: FaqType;
  regionsMvi: Array<RegionMviType>;
  validationResults: Array<Scalars['String']['output']>;
};

export enum RegionFeedbackKindEnum {
  Other = 'Other',
  Region = 'Region',
  RegionIndicator = 'RegionIndicator'
}

export type RegionFeedbackType = {
  __typename?: 'RegionFeedbackType';
  attachments: Array<RatingFeedbackAttachmentType>;
  commentary: Scalars['String']['output'];
  feedbackId: Scalars['Int']['output'];
  /** Ключ кеширования, учитывает regionId, feedbackId, lastPeriodId */
  id: Scalars['String']['output'];
  indicatorId?: Maybe<Scalars['Int']['output']>;
  indicatorName?: Maybe<Scalars['String']['output']>;
  kind: RegionFeedbackKindEnum;
  publishDate: Scalars['String']['output'];
  publisher: Scalars['String']['output'];
  regionId: Scalars['Int']['output'];
  subject?: Maybe<Scalars['String']['output']>;
};

export type RegionIndicatorType = {
  __typename?: 'RegionIndicatorType';
  alertMark: Scalars['Boolean']['output'];
  availableForDetails: Scalars['Boolean']['output'];
  components: Array<IndicatorComponentType>;
  dynamic?: Maybe<Scalars['Float']['output']>;
  feedback?: Maybe<RegionFeedbackType>;
  groupField?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  haveFeedback: Scalars['Boolean']['output'];
  haveRecommendations: Scalars['Boolean']['output'];
  history: Array<IndicatorValueByPeriodType>;
  /** Ключ кеширования */
  id: Scalars['String']['output'];
  indicatorId: Scalars['Int']['output'];
  methodologies: Array<IndicatorMethodologyType>;
  name: Scalars['String']['output'];
  pointsMax: Scalars['Float']['output'];
  pointsTotal: Scalars['Float']['output'];
  pointsTotalPercentage: Scalars['Float']['output'];
  progressGroup: RatingIndicatorProgressGroupEnum;
  recommendations?: Maybe<IndicatorRecommendationsType>;
  regionId: Scalars['Int']['output'];
  responsibles: Array<PrimePersonType>;
  specifications: Array<IndicatorSpecificationType>;
  successMark: Scalars['Boolean']['output'];
  updateFrequency?: Maybe<Scalars['String']['output']>;
  /** Версия показателя рейтинга */
  version: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type RegionIndicatorValueType = {
  __typename?: 'RegionIndicatorValueType';
  indicatorId: Scalars['Int']['output'];
  indicatorName: Scalars['String']['output'];
  maxScore: Scalars['Float']['output'];
  scoreForLastPeriod?: Maybe<Scalars['Float']['output']>;
  scoreForPreviousPeriod?: Maybe<Scalars['Float']['output']>;
  termOfInclusion: Scalars['String']['output'];
};

export type RegionMviType = {
  __typename?: 'RegionMviType';
  averageDegree: Scalars['Float']['output'];
  dynamic?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  pointsAverage: Scalars['Float']['output'];
  pointsMax: Scalars['Float']['output'];
  regionsMaxShare: Scalars['Float']['output'];
  regionsMinShare: Scalars['Float']['output'];
  regionsQuantity: Scalars['Float']['output'];
};

export enum RegionRatingGroupEnum {
  Average = 'Average',
  High = 'High'
}

export type RegionRatingType = {
  __typename?: 'RegionRatingType';
  availableKeyIndicators: Array<AvailableRegionIndicatorType>;
  /** Ключ кеширования */
  id: Scalars['String']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  regions: Array<RegionType>;
};


export type RegionRatingTypeRegionsArgs = {
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RegionType = {
  __typename?: 'RegionType';
  degree?: Maybe<Scalars['Float']['output']>;
  dynamic?: Maybe<Scalars['Float']['output']>;
  feedback?: Maybe<Array<RegionFeedbackType>>;
  /** Ключ кеширования */
  id: Scalars['String']['output'];
  indicators: Array<RegionIndicatorType>;
  indicatorsValues: Array<RegionIndicatorValueType>;
  isCurrentRegion: Scalars['Boolean']['output'];
  lastPeriodId: Scalars['Int']['output'];
  lastUpdateDate?: Maybe<Scalars['String']['output']>;
  pointsLast?: Maybe<Scalars['Float']['output']>;
  pointsMax: Scalars['Float']['output'];
  pointsPrev?: Maybe<Scalars['Float']['output']>;
  previousRatingPosition?: Maybe<Scalars['Int']['output']>;
  previousUpdateDate?: Maybe<Scalars['String']['output']>;
  ratingGroup: RegionRatingGroupEnum;
  ratingPosition: Scalars['Int']['output'];
  recommendations?: Maybe<IndicatorRecommendationsType>;
  regionId: Scalars['Int']['output'];
  regionName: Scalars['String']['output'];
  regionShortCode: Scalars['String']['output'];
  regionShortName: Scalars['String']['output'];
  representatives: Array<PrimePersonType>;
  rrct: Array<PrimePersonType>;
};


export type RegionTypeIndicatorsArgs = {
  indicatorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export type StatsBudgetBalance = {
  __typename?: 'StatsBudgetBalance';
  availableBalance: Scalars['Float']['output'];
  totalBalance: Scalars['Float']['output'];
};

export type StatsIndicators = {
  __typename?: 'StatsIndicators';
  keyIndicatorsCount: Scalars['Float']['output'];
  totalIndicatorsCount: Scalars['Float']['output'];
};

export type StatsType = {
  __typename?: 'StatsType';
  budgetBalance?: Maybe<StatsBudgetBalance>;
  documentsCount?: Maybe<Scalars['Int']['output']>;
  /** Ключ кеширования, учитывает: departmentId (authority) */
  id: Scalars['String']['output'];
  indicators?: Maybe<StatsIndicators>;
  keySystemsCount?: Maybe<Scalars['Int']['output']>;
  operationalRatingLastUpdate?: Maybe<Scalars['String']['output']>;
};

export type TagType = {
  __typename?: 'TagType';
  colorHex: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TagWithCountType = {
  __typename?: 'TagWithCountType';
  colorHex: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type WidgetSettingsInput = {
  /** Id виджета */
  id: Scalars['Int']['input'];
  /** Порядок отображения в списке */
  order: Scalars['Int']['input'];
  /** Флаг: активен-ли виджет */
  visible: Scalars['Boolean']['input'];
};

export type WidgetSettingsType = {
  __typename?: 'WidgetSettingsType';
  /** Флаг: можно-ли изменять настройки виджета */
  changeable: Scalars['Boolean']['output'];
  /** Id виджета */
  id: Scalars['Int']['output'];
  /** Название виджета для отображения в списке */
  name: Scalars['String']['output'];
  /** Порядок отображения в списке */
  order: Scalars['Int']['output'];
  /** Флаг: активен-ли виджет */
  visible: Scalars['Boolean']['output'];
};

export type GetFilePresignedUrlQueryVariables = Exact<{
  entityId: Scalars['Int']['input'];
}>;


export type GetFilePresignedUrlQuery = { __typename?: 'Query', filePresignedUrl: string };

export type WidgetsQueryVariables = Exact<{ [key: string]: never; }>;


export type WidgetsQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', authority: number, widgetSettings: Array<{ __typename?: 'WidgetSettingsType', id: number, name: string, order: number, visible: boolean, changeable: boolean }> } };

export type Widgets_UpdateMutationVariables = Exact<{
  input: Array<WidgetSettingsInput> | WidgetSettingsInput;
}>;


export type Widgets_UpdateMutation = { __typename?: 'Mutation', updateWidgetSettings: Array<{ __typename?: 'WidgetSettingsType', id: number, name: string, order: number, visible: boolean, changeable: boolean }> };

export type UserLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type UserLayoutQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, profile: { __typename?: 'DashboardProfileType', id: number, legacy: boolean, department: { __typename?: 'DashboardProfileDepartmentType', id: string, authority: number, departmentShortName?: string | null }, availableDepartments: Array<{ __typename?: 'DashboardProfileAvailableDepartmentType', authority: number, departmentShortName: string }> } } };

export type BudgetQueryVariables = Exact<{ [key: string]: never; }>;


export type BudgetQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, financialAnalysis: { __typename?: 'FinancialAnalysisType', id: string, departmentId: number, acceptedBudgetCommitments: number, budget: number, cashExecution: number, outBudget?: number | null, remainder: number, spendingDirections?: Array<{ __typename?: 'BudgetSpendingDirectionType', _id: string, directionId: number, name: string, plan2xx: number, otherSpending: number, totalSpending: number, govPrograms?: Array<{ __typename?: 'GovernmentProgramType', _id: string, kind: GovernmentProgramTypeEnum, name: string, otherSpending: number, plan2xx: number, totalSpending: number, govProjects?: Array<{ __typename?: 'GovernmentProjectType', _id: string, kind: GovernmentProjectTypeEnum, name: string, otherSpending: number, plan2xx: number, totalSpending: number, subProjects?: Array<{ __typename?: 'GovernmentSubProjectType', _id: string, kind: GovernmentSubProjectTypeEnum, name: string, otherSpending: number, plan2xx: number, totalSpending: number }> | null }> | null }> | null }> | null } } };

export type DocumentListQueryVariables = Exact<{
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  documentTypes?: InputMaybe<Array<DocumentTypeEnum> | DocumentTypeEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DocumentListQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, documents: { __typename?: 'DocumentsType', departmentId: number, isArchive: boolean, pagination: { __typename?: 'PaginationType', totalCount: number }, items: Array<{ __typename?: 'DocumentType', id: string, documentId: string, documentName: string, documentType: DocumentTypeEnum, status: DocumentStatusEnum, statusText?: string | null, statusDate?: string | null }> } } };

export type DocumentPageQueryVariables = Exact<{
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type DocumentPageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, documents: { __typename?: 'DocumentsType', departmentId: number, isArchive: boolean, items: Array<{ __typename?: 'DocumentType', id: string, documentId: string, documentName: string, documentType: DocumentTypeEnum, status: DocumentStatusEnum, statusText?: string | null, statusDate?: string | null, evaluationObjects: Array<string>, incomingDate?: string | null, incomingNumber?: string | null, deadlineDate?: string | null, deadlineRemainingDays?: number | null, outgoingDate?: string | null, outgoingNumber?: string | null, attachments: Array<{ __typename?: 'DocumentAttachmentType', id: number, fileName: string, fullName: string, fileType: FileTypeEnum, size: number, scope: DocumentAttachmentScopeEnum, updatedAt?: string | null }>, history?: Array<{ __typename?: 'DocumentSnapshotType', date: string, documentName: string, status: DocumentStatusEnum }> | null }> } } };

export type ViewDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;


export type ViewDocumentMutation = { __typename?: 'Mutation', markDocumentAsViewed: { __typename?: 'DocumentType', documentId: string, viewed: boolean } };

export type EventQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type EventQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, events: { __typename?: 'EventsType', entries: Array<{ __typename?: 'EventType', id: number, content: string, header: string, publishDate: string, important: boolean, tags: Array<{ __typename?: 'TagType', id: number, name: string, colorHex: string }>, attachments: Array<{ __typename?: 'FileType', id: number, fullName: string, fileName: string, fileType: FileTypeEnum, size: number, createdAt?: string | null }> }> } } };

export type EventsListQueryVariables = Exact<{
  tagIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  sort?: InputMaybe<SortDirectionEnum>;
  search?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type EventsListQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, events: { __typename?: 'EventsType', pagination: { __typename?: 'PaginationType', totalCount: number }, entries: Array<{ __typename?: 'EventType', id: number, header: string, publishDate: string, important: boolean, tags: Array<{ __typename?: 'TagType', id: number, name: string, colorHex: string }> }> } } };

export type EventsTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsTagsQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, events: { __typename?: 'EventsType', tags: Array<{ __typename?: 'TagWithCountType', id: number, name: string, colorHex: string }> } } };

export type HomePageQueryVariables = Exact<{
  skipFinancialAnalysis: Scalars['Boolean']['input'];
}>;


export type HomePageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, financialAnalysis?: { __typename?: 'FinancialAnalysisType', id: string, departmentId: number, acceptedBudgetCommitments: number, budget: number, cashExecution: number, outBudget?: number | null, remainder: number }, keyIndicators: { __typename?: 'KeyIndicatorsType', _id: string, departmentId: number, key: number, total: number, achievedOrApproved: number, failedOrDisagreement: number, serviceIndicators: boolean }, notifications: { __typename?: 'NotificationsType', id: string, departmentId: number, requests: Array<{ __typename?: 'DepartmentRequestType', id: string, requestId: number, viewed: boolean, requestNumber: string, createdDate: string, statusCode: CommonDepartmentRequestStatusEnum, statusDisplayContent: string, stateUpdatedAt?: string | null, requestName: string, requestTypeName: string }> }, documents: { __typename?: 'DocumentsType', departmentId: number, isArchive: boolean, items: Array<{ __typename?: 'DocumentType', id: string, documentId: string, documentType: DocumentTypeEnum, viewed: boolean, documentName: string, status: DocumentStatusEnum, statusText?: string | null, statusDate?: string | null }> }, widgetSettings: Array<{ __typename?: 'WidgetSettingsType', id: number, name: string, order: number, visible: boolean, changeable: boolean }> } };

export type MetaCardListQueryVariables = Exact<{ [key: string]: never; }>;


export type MetaCardListQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, stats: { __typename?: 'StatsType', id: string, operationalRatingLastUpdate?: string | null, documentsCount?: number | null, keySystemsCount?: number | null, indicators?: { __typename?: 'StatsIndicators', keyIndicatorsCount: number, totalIndicatorsCount: number } | null } } };

export type IndicatorPageQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type IndicatorPageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', authority: number, validationResults: Array<string>, keyIndicators: { __typename?: 'KeyIndicatorsType', _id: string, departmentId: number, serviceIndicators: boolean, indicators: Array<{ __typename?: 'KeyIndicatorType', _id: string, departmentId: number, id: number, code: string, name: string, approved: boolean, approvedFromMc: boolean, approvedFromOgv: boolean, color: KeyIndicatorColorsEnum, valueFor2022?: string | null, valueFor2023?: string | null, valueFor2024?: string | null, valueFor2025?: string | null, valueInfo: string, haveNested: boolean, nestedIndicators: Array<{ __typename?: 'KeyIndicatorType', departmentId: number, id: number, code: string, name: string, color: KeyIndicatorColorsEnum, valueFor2022?: string | null, valueFor2023?: string | null, valueFor2024?: string | null, valueFor2025?: string | null, valueInfo: string }>, history: Array<{ __typename?: 'KeyIndicatorHistoryType', id: number, approvedFromOgv: boolean, changesDate: string, executor: string }> }> } } };

export type IndicatorsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type IndicatorsPageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, keyIndicators: { __typename?: 'KeyIndicatorsType', _id: string, departmentId: number, key: number, total: number, achievedOrApproved: number, failedOrDisagreement: number, serviceIndicators: boolean, indicators: Array<{ __typename?: 'KeyIndicatorType', _id: string, departmentId: number, id: number, name: string, approved: boolean, disagreements: boolean, code: string, mcName: string, ogvName: string }> } } };

export type LibraryQueryVariables = Exact<{ [key: string]: never; }>;


export type LibraryQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, library: { __typename?: 'LibraryType', materials: Array<{ __typename?: 'LibraryMaterialType', id: number, createdAt: string, updatedAt: string, fullName: string, fileName: string, fileType: FileTypeEnum, path: Array<string>, size: number, favorite: boolean, tags: Array<{ __typename?: 'TagType', id: number, name: string, colorHex: string }> }>, tags: Array<{ __typename?: 'TagWithCountType', id: number, name: string, colorHex: string, count: number }> } } };

export type SetLibraryFileFavoriteMutationVariables = Exact<{
  input: LibraryMaterialFavoriteInput;
}>;


export type SetLibraryFileFavoriteMutation = { __typename?: 'Mutation', result: string };

export type UpdateLibraryFileTagsMutationVariables = Exact<{
  input: LibraryMaterialTagsInput;
}>;


export type UpdateLibraryFileTagsMutation = { __typename?: 'Mutation', result: string };

export type ProfilePageQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfilePageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, profile: { __typename?: 'DashboardProfileType', id: number, fullName: string, position: string, email?: string | null, department: { __typename?: 'DashboardProfileDepartmentType', id: string, authority: number, departmentShortName?: string | null } } } };

export type RatingIndicatorPageQueryVariables = Exact<{
  indicatorId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type RatingIndicatorPageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, operationalRating: { __typename?: 'OperationalRatingType', id: string, departmentId: number, currentPeriodId: number, previousPeriodId: number, group?: RatingGroupEnum | null, lastUpdate?: string | null, overallDynamic: RatingDynamicEnum, pointsAchieved?: number | null, pointsAchievedDynamic: RatingDynamicEnum, pointsAchievedPercentage?: number | null, pointsTotal?: number | null, pointsTotalDynamic: RatingDynamicEnum, pointsTotalPercentage: number, operationalRatingIndicators: Array<{ __typename?: 'OperationalRatingIndicatorType', _id: string, departmentId: number, currentPeriodId: number, previousPeriodId: number, id: number, name: string, currentValue?: number | null, maxValue?: number | null, dynamic: RatingDynamicEnum, groupField?: string | null, groupName?: string | null, sourceName?: string | null, sourceUrl?: string | null, updatePeriodicity?: string | null, responsibleName?: string | null, responsibleEmail?: string | null, calculationMethod?: string | null, chart: Array<{ __typename?: 'IndicatorChartPointType', date: string, value: number }> }> } } };

export type RatingPageQueryVariables = Exact<{ [key: string]: never; }>;


export type RatingPageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, operationalRating: { __typename?: 'OperationalRatingType', id: string, departmentId: number, currentPeriodId: number, previousPeriodId: number, group?: RatingGroupEnum | null, lastUpdate?: string | null, overallDynamic: RatingDynamicEnum, pointsAchieved?: number | null, pointsAchievedDynamic: RatingDynamicEnum, pointsAchievedPercentage?: number | null, pointsTotal?: number | null, pointsTotalDynamic: RatingDynamicEnum, pointsTotalPercentage: number, relativeBarDynamicValue?: number | null, operationalRatingIndicators: Array<{ __typename?: 'OperationalRatingIndicatorType', _id: string, departmentId: number, currentPeriodId: number, previousPeriodId: number, id: number, name: string, currentValue?: number | null, maxValue?: number | null, dynamic: RatingDynamicEnum, groupField?: string | null, groupName?: string | null, sourceName?: string | null, sourceUrl?: string | null, updatePeriodicity?: string | null, responsibleName?: string | null, responsibleEmail?: string | null, calculationMethod?: string | null, chart: Array<{ __typename?: 'IndicatorChartPointType', date: string, value: number }> }> } } };

export type RequestPageQueryVariables = Exact<{ [key: string]: never; }>;


export type RequestPageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, notifications: { __typename?: 'NotificationsType', id: string, departmentId: number, requests: Array<{ __typename?: 'DepartmentRequestType', id: string, requestId: number, requestNumber: string, createdDate: string, statusCode: CommonDepartmentRequestStatusEnum, statusDisplayContent: string, stateUpdatedAt?: string | null, groundsNames: Array<string>, requestName: string, requestTypeName: string }> } } };

export type ViewRequestMutationVariables = Exact<{
  requestId: Scalars['Int']['input'];
}>;


export type ViewRequestMutation = { __typename?: 'Mutation', markRequestAsViewed: { __typename?: 'DepartmentRequestType', requestId: number, viewed: boolean } };

export type ServicesPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ServicesPageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, profile: { __typename?: 'DashboardProfileType', id: number, accessToChangeKeyIndicators: boolean } } };

export type IndicatorAgreementQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type IndicatorAgreementQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, keyIndicators: { __typename?: 'KeyIndicatorsType', _id: string, departmentId: number, serviceIndicators: boolean, indicators: Array<{ __typename?: 'KeyIndicatorType', _id: string, departmentId: number, id: number, code: string, name: string, approved: boolean, approvedFromMc: boolean, approvedFromOgv: boolean, color: KeyIndicatorColorsEnum, valueFor2022?: string | null, valueFor2023?: string | null, valueFor2024?: string | null, valueFor2025?: string | null, valueInfo: string, haveNested: boolean, curators: Array<{ __typename?: 'KeyIndicatorCuratorType', name: string, email?: string | null }>, nestedIndicators: Array<{ __typename?: 'KeyIndicatorType', departmentId: number, id: number, code: string, name: string, color: KeyIndicatorColorsEnum, valueFor2022?: string | null, valueFor2023?: string | null, valueFor2024?: string | null, valueFor2025?: string | null, valueInfo: string }>, history: Array<{ __typename?: 'KeyIndicatorHistoryType', id: number, approvedFromOgv: boolean, changesDate: string, executor: string }> }> } } };

export type ChangeDepartmentPositionMutationVariables = Exact<{
  keyIndicatorId: Scalars['Int']['input'];
}>;


export type ChangeDepartmentPositionMutation = { __typename?: 'Mutation', changeDepartmentPosition: { __typename?: 'KeyIndicatorType', id: number, departmentId: number } };

export type IndicatorsAgreementQueryVariables = Exact<{ [key: string]: never; }>;


export type IndicatorsAgreementQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, keyIndicators: { __typename?: 'KeyIndicatorsType', _id: string, departmentId: number, total: number, key: number, achievedOrApproved: number, failedOrDisagreement: number, serviceIndicators: boolean, indicators: Array<{ __typename?: 'KeyIndicatorType', _id: string, departmentId: number, id: number, name: string, approved: boolean, disagreements: boolean, isKeyIndicator: boolean, code: string, mcName: string, ogvName: string }> } } };

export type SystemsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemsPageQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, keySystems: { __typename?: 'KeySystemsType', id: string, departmentId: number, keyCount: number, totalCount: number, totalBudget: number, systems: Array<{ __typename?: 'KeySystemType', _id: string, id: number, name: string, budgetTotal: number, budgetDevelopment: number, budgetExploitation: number }> } } };

export type AuthorityQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthorityQuery = { __typename?: 'Query', dashboard: { __typename?: 'DashboardType', id: string, authority: number, validationResults: Array<string>, profile: { __typename?: 'DashboardProfileType', id: number, legacy: boolean, accessToTagLibraryMaterials: boolean, department: { __typename?: 'DashboardProfileDepartmentType', id: string, authority: number, departmentShortName?: string | null, departmentFullName?: string | null }, availableDepartments: Array<{ __typename?: 'DashboardProfileAvailableDepartmentType', authority: number, departmentShortName: string, departmentFullName: string }> } } };


export const GetFilePresignedUrlDocument = gql`
    query GetFilePresignedUrl($entityId: Int!) {
  filePresignedUrl(entityId: $entityId)
}
    `;

/**
 * __useGetFilePresignedUrlQuery__
 *
 * To run a query within a React component, call `useGetFilePresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilePresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilePresignedUrlQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGetFilePresignedUrlQuery(baseOptions: Apollo.QueryHookOptions<GetFilePresignedUrlQuery, GetFilePresignedUrlQueryVariables> & ({ variables: GetFilePresignedUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilePresignedUrlQuery, GetFilePresignedUrlQueryVariables>(GetFilePresignedUrlDocument, options);
      }
export function useGetFilePresignedUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilePresignedUrlQuery, GetFilePresignedUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilePresignedUrlQuery, GetFilePresignedUrlQueryVariables>(GetFilePresignedUrlDocument, options);
        }
export function useGetFilePresignedUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFilePresignedUrlQuery, GetFilePresignedUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFilePresignedUrlQuery, GetFilePresignedUrlQueryVariables>(GetFilePresignedUrlDocument, options);
        }
export type GetFilePresignedUrlQueryHookResult = ReturnType<typeof useGetFilePresignedUrlQuery>;
export type GetFilePresignedUrlLazyQueryHookResult = ReturnType<typeof useGetFilePresignedUrlLazyQuery>;
export type GetFilePresignedUrlSuspenseQueryHookResult = ReturnType<typeof useGetFilePresignedUrlSuspenseQuery>;
export type GetFilePresignedUrlQueryResult = Apollo.QueryResult<GetFilePresignedUrlQuery, GetFilePresignedUrlQueryVariables>;
export const WidgetsDocument = gql`
    query widgets {
  dashboard {
    authority
    widgetSettings {
      id
      name
      order
      visible
      changeable
    }
  }
}
    `;

/**
 * __useWidgetsQuery__
 *
 * To run a query within a React component, call `useWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWidgetsQuery(baseOptions?: Apollo.QueryHookOptions<WidgetsQuery, WidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, options);
      }
export function useWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetsQuery, WidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, options);
        }
export function useWidgetsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WidgetsQuery, WidgetsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, options);
        }
export type WidgetsQueryHookResult = ReturnType<typeof useWidgetsQuery>;
export type WidgetsLazyQueryHookResult = ReturnType<typeof useWidgetsLazyQuery>;
export type WidgetsSuspenseQueryHookResult = ReturnType<typeof useWidgetsSuspenseQuery>;
export type WidgetsQueryResult = Apollo.QueryResult<WidgetsQuery, WidgetsQueryVariables>;
export const Widgets_UpdateDocument = gql`
    mutation widgets_update($input: [WidgetSettingsInput!]!) {
  updateWidgetSettings(input: $input) {
    id
    name
    order
    visible
    changeable
  }
}
    `;
export type Widgets_UpdateMutationFn = Apollo.MutationFunction<Widgets_UpdateMutation, Widgets_UpdateMutationVariables>;

/**
 * __useWidgets_UpdateMutation__
 *
 * To run a mutation, you first call `useWidgets_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgets_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetsUpdateMutation, { data, loading, error }] = useWidgets_UpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWidgets_UpdateMutation(baseOptions?: Apollo.MutationHookOptions<Widgets_UpdateMutation, Widgets_UpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Widgets_UpdateMutation, Widgets_UpdateMutationVariables>(Widgets_UpdateDocument, options);
      }
export type Widgets_UpdateMutationHookResult = ReturnType<typeof useWidgets_UpdateMutation>;
export type Widgets_UpdateMutationResult = Apollo.MutationResult<Widgets_UpdateMutation>;
export type Widgets_UpdateMutationOptions = Apollo.BaseMutationOptions<Widgets_UpdateMutation, Widgets_UpdateMutationVariables>;
export const UserLayoutDocument = gql`
    query UserLayout {
  dashboard {
    id
    authority
    validationResults
    profile {
      id
      legacy
      department {
        id
        authority
        departmentShortName
      }
      availableDepartments {
        authority
        departmentShortName
      }
    }
  }
}
    `;

/**
 * __useUserLayoutQuery__
 *
 * To run a query within a React component, call `useUserLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLayoutQuery(baseOptions?: Apollo.QueryHookOptions<UserLayoutQuery, UserLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLayoutQuery, UserLayoutQueryVariables>(UserLayoutDocument, options);
      }
export function useUserLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLayoutQuery, UserLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLayoutQuery, UserLayoutQueryVariables>(UserLayoutDocument, options);
        }
export function useUserLayoutSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserLayoutQuery, UserLayoutQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserLayoutQuery, UserLayoutQueryVariables>(UserLayoutDocument, options);
        }
export type UserLayoutQueryHookResult = ReturnType<typeof useUserLayoutQuery>;
export type UserLayoutLazyQueryHookResult = ReturnType<typeof useUserLayoutLazyQuery>;
export type UserLayoutSuspenseQueryHookResult = ReturnType<typeof useUserLayoutSuspenseQuery>;
export type UserLayoutQueryResult = Apollo.QueryResult<UserLayoutQuery, UserLayoutQueryVariables>;
export const BudgetDocument = gql`
    query Budget {
  dashboard {
    id
    authority
    validationResults
    financialAnalysis {
      id
      departmentId
      acceptedBudgetCommitments
      budget
      cashExecution
      outBudget
      remainder
      spendingDirections {
        _id
        directionId
        name
        plan2xx
        otherSpending
        totalSpending
        govPrograms {
          _id
          kind
          name
          otherSpending
          plan2xx
          totalSpending
          govProjects {
            _id
            kind
            name
            otherSpending
            plan2xx
            totalSpending
            subProjects {
              _id
              kind
              name
              otherSpending
              plan2xx
              totalSpending
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBudgetQuery__
 *
 * To run a query within a React component, call `useBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetQuery({
 *   variables: {
 *   },
 * });
 */
export function useBudgetQuery(baseOptions?: Apollo.QueryHookOptions<BudgetQuery, BudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BudgetQuery, BudgetQueryVariables>(BudgetDocument, options);
      }
export function useBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetQuery, BudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BudgetQuery, BudgetQueryVariables>(BudgetDocument, options);
        }
export function useBudgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BudgetQuery, BudgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BudgetQuery, BudgetQueryVariables>(BudgetDocument, options);
        }
export type BudgetQueryHookResult = ReturnType<typeof useBudgetQuery>;
export type BudgetLazyQueryHookResult = ReturnType<typeof useBudgetLazyQuery>;
export type BudgetSuspenseQueryHookResult = ReturnType<typeof useBudgetSuspenseQuery>;
export type BudgetQueryResult = Apollo.QueryResult<BudgetQuery, BudgetQueryVariables>;
export const DocumentListDocument = gql`
    query DocumentList($archive: Boolean, $documentTypes: [DocumentTypeEnum!], $search: String, $limit: Int, $offset: Int) {
  dashboard {
    id
    authority
    validationResults
    documents(
      archive: $archive
      input: {pagination: {limit: $limit, offset: $offset}, filters: {documentTypes: $documentTypes, searchQuery: $search}}
    ) {
      departmentId
      isArchive
      pagination {
        totalCount
      }
      items {
        id
        documentId
        documentName
        documentType
        status
        statusText
        statusDate
      }
    }
  }
}
    `;

/**
 * __useDocumentListQuery__
 *
 * To run a query within a React component, call `useDocumentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListQuery({
 *   variables: {
 *      archive: // value for 'archive'
 *      documentTypes: // value for 'documentTypes'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDocumentListQuery(baseOptions?: Apollo.QueryHookOptions<DocumentListQuery, DocumentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentListQuery, DocumentListQueryVariables>(DocumentListDocument, options);
      }
export function useDocumentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentListQuery, DocumentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentListQuery, DocumentListQueryVariables>(DocumentListDocument, options);
        }
export function useDocumentListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocumentListQuery, DocumentListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentListQuery, DocumentListQueryVariables>(DocumentListDocument, options);
        }
export type DocumentListQueryHookResult = ReturnType<typeof useDocumentListQuery>;
export type DocumentListLazyQueryHookResult = ReturnType<typeof useDocumentListLazyQuery>;
export type DocumentListSuspenseQueryHookResult = ReturnType<typeof useDocumentListSuspenseQuery>;
export type DocumentListQueryResult = Apollo.QueryResult<DocumentListQuery, DocumentListQueryVariables>;
export const DocumentPageDocument = gql`
    query DocumentPage($archive: Boolean, $ids: [String!]) {
  dashboard {
    id
    authority
    validationResults
    documents(archive: $archive, input: {filters: {ids: $ids}, withFiles: true}) {
      departmentId
      isArchive
      items {
        id
        documentId
        documentName
        documentType
        status
        statusText
        statusDate
        evaluationObjects
        incomingDate
        incomingNumber
        deadlineDate
        deadlineRemainingDays
        outgoingDate
        outgoingNumber
        attachments {
          id
          fileName
          fullName
          fileType
          size
          scope
          updatedAt
        }
        history {
          date
          documentName
          status
        }
      }
    }
  }
}
    `;

/**
 * __useDocumentPageQuery__
 *
 * To run a query within a React component, call `useDocumentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentPageQuery({
 *   variables: {
 *      archive: // value for 'archive'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDocumentPageQuery(baseOptions?: Apollo.QueryHookOptions<DocumentPageQuery, DocumentPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentPageQuery, DocumentPageQueryVariables>(DocumentPageDocument, options);
      }
export function useDocumentPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentPageQuery, DocumentPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentPageQuery, DocumentPageQueryVariables>(DocumentPageDocument, options);
        }
export function useDocumentPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocumentPageQuery, DocumentPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentPageQuery, DocumentPageQueryVariables>(DocumentPageDocument, options);
        }
export type DocumentPageQueryHookResult = ReturnType<typeof useDocumentPageQuery>;
export type DocumentPageLazyQueryHookResult = ReturnType<typeof useDocumentPageLazyQuery>;
export type DocumentPageSuspenseQueryHookResult = ReturnType<typeof useDocumentPageSuspenseQuery>;
export type DocumentPageQueryResult = Apollo.QueryResult<DocumentPageQuery, DocumentPageQueryVariables>;
export const ViewDocumentDocument = gql`
    mutation ViewDocument($documentId: String!) {
  markDocumentAsViewed(documentId: $documentId) {
    documentId
    viewed
  }
}
    `;
export type ViewDocumentMutationFn = Apollo.MutationFunction<ViewDocumentMutation, ViewDocumentMutationVariables>;

/**
 * __useViewDocumentMutation__
 *
 * To run a mutation, you first call `useViewDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewDocumentMutation, { data, loading, error }] = useViewDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useViewDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ViewDocumentMutation, ViewDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewDocumentMutation, ViewDocumentMutationVariables>(ViewDocumentDocument, options);
      }
export type ViewDocumentMutationHookResult = ReturnType<typeof useViewDocumentMutation>;
export type ViewDocumentMutationResult = Apollo.MutationResult<ViewDocumentMutation>;
export type ViewDocumentMutationOptions = Apollo.BaseMutationOptions<ViewDocumentMutation, ViewDocumentMutationVariables>;
export const EventDocument = gql`
    query Event($ids: [Int!]) {
  dashboard {
    id
    authority
    validationResults
    events(input: {filters: {ids: $ids}}) {
      entries {
        id
        content
        header
        publishDate
        important
        tags {
          id
          name
          colorHex
        }
        attachments {
          id
          fullName
          fileName
          fileType
          size
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useEventQuery(baseOptions?: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export function useEventSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventSuspenseQueryHookResult = ReturnType<typeof useEventSuspenseQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventsListDocument = gql`
    query EventsList($tagIds: [Int!], $sort: SortDirectionEnum, $search: String, $limit: Int, $offset: Int) {
  dashboard {
    id
    authority
    validationResults
    events(
      input: {pagination: {limit: $limit, offset: $offset}, filters: {tagIds: $tagIds, searchQuery: $search}, sort: {date: $sort}}
    ) {
      pagination {
        totalCount
      }
      entries {
        id
        header
        publishDate
        important
        tags {
          id
          name
          colorHex
        }
      }
    }
  }
}
    `;

/**
 * __useEventsListQuery__
 *
 * To run a query within a React component, call `useEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsListQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useEventsListQuery(baseOptions?: Apollo.QueryHookOptions<EventsListQuery, EventsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, options);
      }
export function useEventsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsListQuery, EventsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, options);
        }
export function useEventsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EventsListQuery, EventsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventsListQuery, EventsListQueryVariables>(EventsListDocument, options);
        }
export type EventsListQueryHookResult = ReturnType<typeof useEventsListQuery>;
export type EventsListLazyQueryHookResult = ReturnType<typeof useEventsListLazyQuery>;
export type EventsListSuspenseQueryHookResult = ReturnType<typeof useEventsListSuspenseQuery>;
export type EventsListQueryResult = Apollo.QueryResult<EventsListQuery, EventsListQueryVariables>;
export const EventsTagsDocument = gql`
    query EventsTags {
  dashboard {
    id
    authority
    validationResults
    events {
      tags: tagsWithCount {
        id
        name
        colorHex
      }
    }
  }
}
    `;

/**
 * __useEventsTagsQuery__
 *
 * To run a query within a React component, call `useEventsTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsTagsQuery(baseOptions?: Apollo.QueryHookOptions<EventsTagsQuery, EventsTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsTagsQuery, EventsTagsQueryVariables>(EventsTagsDocument, options);
      }
export function useEventsTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsTagsQuery, EventsTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsTagsQuery, EventsTagsQueryVariables>(EventsTagsDocument, options);
        }
export function useEventsTagsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EventsTagsQuery, EventsTagsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventsTagsQuery, EventsTagsQueryVariables>(EventsTagsDocument, options);
        }
export type EventsTagsQueryHookResult = ReturnType<typeof useEventsTagsQuery>;
export type EventsTagsLazyQueryHookResult = ReturnType<typeof useEventsTagsLazyQuery>;
export type EventsTagsSuspenseQueryHookResult = ReturnType<typeof useEventsTagsSuspenseQuery>;
export type EventsTagsQueryResult = Apollo.QueryResult<EventsTagsQuery, EventsTagsQueryVariables>;
export const HomePageDocument = gql`
    query HomePage($skipFinancialAnalysis: Boolean!) {
  dashboard {
    id
    authority
    validationResults
    financialAnalysis(onlyBudget: true) @skip(if: $skipFinancialAnalysis) {
      id
      departmentId
      acceptedBudgetCommitments
      budget
      cashExecution
      outBudget
      remainder
    }
    keyIndicators {
      _id
      departmentId
      key
      total
      achievedOrApproved
      failedOrDisagreement
      serviceIndicators
    }
    notifications {
      id
      departmentId
      requests {
        id
        requestId
        viewed
        requestNumber
        createdDate
        statusCode
        statusDisplayContent
        stateUpdatedAt
        requestName
        requestTypeName
      }
    }
    documents(archive: false) {
      departmentId
      isArchive
      items {
        id
        documentId
        documentType
        viewed
        documentName
        status
        statusText
        statusDate
      }
    }
    widgetSettings {
      id
      name
      order
      visible
      changeable
    }
  }
}
    `;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *      skipFinancialAnalysis: // value for 'skipFinancialAnalysis'
 *   },
 * });
 */
export function useHomePageQuery(baseOptions: Apollo.QueryHookOptions<HomePageQuery, HomePageQueryVariables> & ({ variables: HomePageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
      }
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
        }
export function useHomePageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HomePageQuery, HomePageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomePageQuery, HomePageQueryVariables>(HomePageDocument, options);
        }
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageSuspenseQueryHookResult = ReturnType<typeof useHomePageSuspenseQuery>;
export type HomePageQueryResult = Apollo.QueryResult<HomePageQuery, HomePageQueryVariables>;
export const MetaCardListDocument = gql`
    query MetaCardList {
  dashboard {
    id
    authority
    validationResults
    stats {
      id
      operationalRatingLastUpdate
      indicators {
        keyIndicatorsCount
        totalIndicatorsCount
      }
      documentsCount
      keySystemsCount
    }
  }
}
    `;

/**
 * __useMetaCardListQuery__
 *
 * To run a query within a React component, call `useMetaCardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaCardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaCardListQuery({
 *   variables: {
 *   },
 * });
 */
export function useMetaCardListQuery(baseOptions?: Apollo.QueryHookOptions<MetaCardListQuery, MetaCardListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetaCardListQuery, MetaCardListQueryVariables>(MetaCardListDocument, options);
      }
export function useMetaCardListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetaCardListQuery, MetaCardListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetaCardListQuery, MetaCardListQueryVariables>(MetaCardListDocument, options);
        }
export function useMetaCardListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MetaCardListQuery, MetaCardListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MetaCardListQuery, MetaCardListQueryVariables>(MetaCardListDocument, options);
        }
export type MetaCardListQueryHookResult = ReturnType<typeof useMetaCardListQuery>;
export type MetaCardListLazyQueryHookResult = ReturnType<typeof useMetaCardListLazyQuery>;
export type MetaCardListSuspenseQueryHookResult = ReturnType<typeof useMetaCardListSuspenseQuery>;
export type MetaCardListQueryResult = Apollo.QueryResult<MetaCardListQuery, MetaCardListQueryVariables>;
export const IndicatorPageDocument = gql`
    query IndicatorPage($ids: [Int!]) {
  dashboard {
    authority
    validationResults
    keyIndicators {
      _id
      departmentId
      serviceIndicators
      indicators(ids: $ids) {
        _id
        departmentId
        id
        code
        name
        approved
        approvedFromMc
        approvedFromOgv
        color
        valueFor2022
        valueFor2023
        valueFor2024
        valueFor2025
        valueInfo
        haveNested
        nestedIndicators {
          departmentId
          id
          code
          name
          color
          valueFor2022
          valueFor2023
          valueFor2024
          valueFor2025
          valueInfo
        }
        history {
          id
          approvedFromOgv
          changesDate
          executor
        }
      }
    }
  }
}
    `;

/**
 * __useIndicatorPageQuery__
 *
 * To run a query within a React component, call `useIndicatorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicatorPageQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useIndicatorPageQuery(baseOptions?: Apollo.QueryHookOptions<IndicatorPageQuery, IndicatorPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndicatorPageQuery, IndicatorPageQueryVariables>(IndicatorPageDocument, options);
      }
export function useIndicatorPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndicatorPageQuery, IndicatorPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndicatorPageQuery, IndicatorPageQueryVariables>(IndicatorPageDocument, options);
        }
export function useIndicatorPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IndicatorPageQuery, IndicatorPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IndicatorPageQuery, IndicatorPageQueryVariables>(IndicatorPageDocument, options);
        }
export type IndicatorPageQueryHookResult = ReturnType<typeof useIndicatorPageQuery>;
export type IndicatorPageLazyQueryHookResult = ReturnType<typeof useIndicatorPageLazyQuery>;
export type IndicatorPageSuspenseQueryHookResult = ReturnType<typeof useIndicatorPageSuspenseQuery>;
export type IndicatorPageQueryResult = Apollo.QueryResult<IndicatorPageQuery, IndicatorPageQueryVariables>;
export const IndicatorsPageDocument = gql`
    query IndicatorsPage {
  dashboard {
    id
    authority
    validationResults
    keyIndicators {
      _id
      departmentId
      key
      total
      achievedOrApproved
      failedOrDisagreement
      serviceIndicators
      indicators {
        _id
        departmentId
        id
        name
        approved
        disagreements
        code
        mcName
        ogvName
      }
    }
  }
}
    `;

/**
 * __useIndicatorsPageQuery__
 *
 * To run a query within a React component, call `useIndicatorsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicatorsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndicatorsPageQuery(baseOptions?: Apollo.QueryHookOptions<IndicatorsPageQuery, IndicatorsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndicatorsPageQuery, IndicatorsPageQueryVariables>(IndicatorsPageDocument, options);
      }
export function useIndicatorsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndicatorsPageQuery, IndicatorsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndicatorsPageQuery, IndicatorsPageQueryVariables>(IndicatorsPageDocument, options);
        }
export function useIndicatorsPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IndicatorsPageQuery, IndicatorsPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IndicatorsPageQuery, IndicatorsPageQueryVariables>(IndicatorsPageDocument, options);
        }
export type IndicatorsPageQueryHookResult = ReturnType<typeof useIndicatorsPageQuery>;
export type IndicatorsPageLazyQueryHookResult = ReturnType<typeof useIndicatorsPageLazyQuery>;
export type IndicatorsPageSuspenseQueryHookResult = ReturnType<typeof useIndicatorsPageSuspenseQuery>;
export type IndicatorsPageQueryResult = Apollo.QueryResult<IndicatorsPageQuery, IndicatorsPageQueryVariables>;
export const LibraryDocument = gql`
    query Library {
  dashboard {
    id
    authority
    validationResults
    library {
      materials {
        id
        createdAt
        updatedAt
        fullName
        fileName
        fileType
        path
        size
        favorite
        tags {
          id
          name
          colorHex
        }
      }
      tags {
        id
        name
        colorHex
        count
      }
    }
  }
}
    `;

/**
 * __useLibraryQuery__
 *
 * To run a query within a React component, call `useLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryQuery({
 *   variables: {
 *   },
 * });
 */
export function useLibraryQuery(baseOptions?: Apollo.QueryHookOptions<LibraryQuery, LibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LibraryQuery, LibraryQueryVariables>(LibraryDocument, options);
      }
export function useLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LibraryQuery, LibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LibraryQuery, LibraryQueryVariables>(LibraryDocument, options);
        }
export function useLibrarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LibraryQuery, LibraryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LibraryQuery, LibraryQueryVariables>(LibraryDocument, options);
        }
export type LibraryQueryHookResult = ReturnType<typeof useLibraryQuery>;
export type LibraryLazyQueryHookResult = ReturnType<typeof useLibraryLazyQuery>;
export type LibrarySuspenseQueryHookResult = ReturnType<typeof useLibrarySuspenseQuery>;
export type LibraryQueryResult = Apollo.QueryResult<LibraryQuery, LibraryQueryVariables>;
export const SetLibraryFileFavoriteDocument = gql`
    mutation SetLibraryFileFavorite($input: LibraryMaterialFavoriteInput!) {
  result: updateLibraryMaterialFavorite(input: $input)
}
    `;
export type SetLibraryFileFavoriteMutationFn = Apollo.MutationFunction<SetLibraryFileFavoriteMutation, SetLibraryFileFavoriteMutationVariables>;

/**
 * __useSetLibraryFileFavoriteMutation__
 *
 * To run a mutation, you first call `useSetLibraryFileFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLibraryFileFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLibraryFileFavoriteMutation, { data, loading, error }] = useSetLibraryFileFavoriteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLibraryFileFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<SetLibraryFileFavoriteMutation, SetLibraryFileFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLibraryFileFavoriteMutation, SetLibraryFileFavoriteMutationVariables>(SetLibraryFileFavoriteDocument, options);
      }
export type SetLibraryFileFavoriteMutationHookResult = ReturnType<typeof useSetLibraryFileFavoriteMutation>;
export type SetLibraryFileFavoriteMutationResult = Apollo.MutationResult<SetLibraryFileFavoriteMutation>;
export type SetLibraryFileFavoriteMutationOptions = Apollo.BaseMutationOptions<SetLibraryFileFavoriteMutation, SetLibraryFileFavoriteMutationVariables>;
export const UpdateLibraryFileTagsDocument = gql`
    mutation UpdateLibraryFileTags($input: LibraryMaterialTagsInput!) {
  result: updateLibraryMaterialTags(input: $input)
}
    `;
export type UpdateLibraryFileTagsMutationFn = Apollo.MutationFunction<UpdateLibraryFileTagsMutation, UpdateLibraryFileTagsMutationVariables>;

/**
 * __useUpdateLibraryFileTagsMutation__
 *
 * To run a mutation, you first call `useUpdateLibraryFileTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLibraryFileTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLibraryFileTagsMutation, { data, loading, error }] = useUpdateLibraryFileTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLibraryFileTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLibraryFileTagsMutation, UpdateLibraryFileTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLibraryFileTagsMutation, UpdateLibraryFileTagsMutationVariables>(UpdateLibraryFileTagsDocument, options);
      }
export type UpdateLibraryFileTagsMutationHookResult = ReturnType<typeof useUpdateLibraryFileTagsMutation>;
export type UpdateLibraryFileTagsMutationResult = Apollo.MutationResult<UpdateLibraryFileTagsMutation>;
export type UpdateLibraryFileTagsMutationOptions = Apollo.BaseMutationOptions<UpdateLibraryFileTagsMutation, UpdateLibraryFileTagsMutationVariables>;
export const ProfilePageDocument = gql`
    query ProfilePage {
  dashboard {
    id
    authority
    validationResults
    profile {
      id
      fullName
      position
      email
      department {
        id
        authority
        departmentShortName
      }
    }
  }
}
    `;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
      }
export function useProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
        }
export function useProfilePageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageSuspenseQueryHookResult = ReturnType<typeof useProfilePageSuspenseQuery>;
export type ProfilePageQueryResult = Apollo.QueryResult<ProfilePageQuery, ProfilePageQueryVariables>;
export const RatingIndicatorPageDocument = gql`
    query RatingIndicatorPage($indicatorId: [Int!]) {
  dashboard {
    id
    authority
    validationResults
    operationalRating {
      id
      departmentId
      currentPeriodId
      previousPeriodId
      group
      lastUpdate
      overallDynamic
      pointsAchieved
      pointsAchievedDynamic
      pointsAchievedPercentage
      pointsTotal
      pointsTotalDynamic
      pointsTotalPercentage
      operationalRatingIndicators(ids: $indicatorId) {
        _id
        departmentId
        currentPeriodId
        previousPeriodId
        id
        name
        currentValue
        maxValue
        dynamic
        groupField
        groupName
        chart {
          date
          value
        }
        sourceName
        sourceUrl
        updatePeriodicity
        responsibleName
        responsibleEmail
        calculationMethod
      }
    }
  }
}
    `;

/**
 * __useRatingIndicatorPageQuery__
 *
 * To run a query within a React component, call `useRatingIndicatorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingIndicatorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingIndicatorPageQuery({
 *   variables: {
 *      indicatorId: // value for 'indicatorId'
 *   },
 * });
 */
export function useRatingIndicatorPageQuery(baseOptions?: Apollo.QueryHookOptions<RatingIndicatorPageQuery, RatingIndicatorPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RatingIndicatorPageQuery, RatingIndicatorPageQueryVariables>(RatingIndicatorPageDocument, options);
      }
export function useRatingIndicatorPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RatingIndicatorPageQuery, RatingIndicatorPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RatingIndicatorPageQuery, RatingIndicatorPageQueryVariables>(RatingIndicatorPageDocument, options);
        }
export function useRatingIndicatorPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RatingIndicatorPageQuery, RatingIndicatorPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RatingIndicatorPageQuery, RatingIndicatorPageQueryVariables>(RatingIndicatorPageDocument, options);
        }
export type RatingIndicatorPageQueryHookResult = ReturnType<typeof useRatingIndicatorPageQuery>;
export type RatingIndicatorPageLazyQueryHookResult = ReturnType<typeof useRatingIndicatorPageLazyQuery>;
export type RatingIndicatorPageSuspenseQueryHookResult = ReturnType<typeof useRatingIndicatorPageSuspenseQuery>;
export type RatingIndicatorPageQueryResult = Apollo.QueryResult<RatingIndicatorPageQuery, RatingIndicatorPageQueryVariables>;
export const RatingPageDocument = gql`
    query RatingPage {
  dashboard {
    id
    authority
    validationResults
    operationalRating {
      id
      departmentId
      currentPeriodId
      previousPeriodId
      group
      lastUpdate
      overallDynamic
      pointsAchieved
      pointsAchievedDynamic
      pointsAchievedPercentage
      pointsTotal
      pointsTotalDynamic
      pointsTotalPercentage
      relativeBarDynamicValue
      operationalRatingIndicators {
        _id
        departmentId
        currentPeriodId
        previousPeriodId
        id
        name
        currentValue
        maxValue
        dynamic
        groupField
        groupName
        chart {
          date
          value
        }
        sourceName
        sourceUrl
        updatePeriodicity
        responsibleName
        responsibleEmail
        calculationMethod
      }
    }
  }
}
    `;

/**
 * __useRatingPageQuery__
 *
 * To run a query within a React component, call `useRatingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRatingPageQuery(baseOptions?: Apollo.QueryHookOptions<RatingPageQuery, RatingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RatingPageQuery, RatingPageQueryVariables>(RatingPageDocument, options);
      }
export function useRatingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RatingPageQuery, RatingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RatingPageQuery, RatingPageQueryVariables>(RatingPageDocument, options);
        }
export function useRatingPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RatingPageQuery, RatingPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RatingPageQuery, RatingPageQueryVariables>(RatingPageDocument, options);
        }
export type RatingPageQueryHookResult = ReturnType<typeof useRatingPageQuery>;
export type RatingPageLazyQueryHookResult = ReturnType<typeof useRatingPageLazyQuery>;
export type RatingPageSuspenseQueryHookResult = ReturnType<typeof useRatingPageSuspenseQuery>;
export type RatingPageQueryResult = Apollo.QueryResult<RatingPageQuery, RatingPageQueryVariables>;
export const RequestPageDocument = gql`
    query RequestPage {
  dashboard {
    id
    authority
    validationResults
    notifications {
      id
      departmentId
      requests {
        id
        requestId
        requestNumber
        createdDate
        statusCode
        statusDisplayContent
        stateUpdatedAt
        groundsNames
        requestName
        requestTypeName
      }
    }
  }
}
    `;

/**
 * __useRequestPageQuery__
 *
 * To run a query within a React component, call `useRequestPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestPageQuery(baseOptions?: Apollo.QueryHookOptions<RequestPageQuery, RequestPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestPageQuery, RequestPageQueryVariables>(RequestPageDocument, options);
      }
export function useRequestPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestPageQuery, RequestPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestPageQuery, RequestPageQueryVariables>(RequestPageDocument, options);
        }
export function useRequestPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RequestPageQuery, RequestPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequestPageQuery, RequestPageQueryVariables>(RequestPageDocument, options);
        }
export type RequestPageQueryHookResult = ReturnType<typeof useRequestPageQuery>;
export type RequestPageLazyQueryHookResult = ReturnType<typeof useRequestPageLazyQuery>;
export type RequestPageSuspenseQueryHookResult = ReturnType<typeof useRequestPageSuspenseQuery>;
export type RequestPageQueryResult = Apollo.QueryResult<RequestPageQuery, RequestPageQueryVariables>;
export const ViewRequestDocument = gql`
    mutation ViewRequest($requestId: Int!) {
  markRequestAsViewed(requestId: $requestId) {
    requestId
    viewed
  }
}
    `;
export type ViewRequestMutationFn = Apollo.MutationFunction<ViewRequestMutation, ViewRequestMutationVariables>;

/**
 * __useViewRequestMutation__
 *
 * To run a mutation, you first call `useViewRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewRequestMutation, { data, loading, error }] = useViewRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useViewRequestMutation(baseOptions?: Apollo.MutationHookOptions<ViewRequestMutation, ViewRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewRequestMutation, ViewRequestMutationVariables>(ViewRequestDocument, options);
      }
export type ViewRequestMutationHookResult = ReturnType<typeof useViewRequestMutation>;
export type ViewRequestMutationResult = Apollo.MutationResult<ViewRequestMutation>;
export type ViewRequestMutationOptions = Apollo.BaseMutationOptions<ViewRequestMutation, ViewRequestMutationVariables>;
export const ServicesPageDocument = gql`
    query ServicesPage {
  dashboard {
    id
    authority
    validationResults
    profile {
      id
      accessToChangeKeyIndicators
    }
  }
}
    `;

/**
 * __useServicesPageQuery__
 *
 * To run a query within a React component, call `useServicesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesPageQuery(baseOptions?: Apollo.QueryHookOptions<ServicesPageQuery, ServicesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesPageQuery, ServicesPageQueryVariables>(ServicesPageDocument, options);
      }
export function useServicesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesPageQuery, ServicesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesPageQuery, ServicesPageQueryVariables>(ServicesPageDocument, options);
        }
export function useServicesPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ServicesPageQuery, ServicesPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ServicesPageQuery, ServicesPageQueryVariables>(ServicesPageDocument, options);
        }
export type ServicesPageQueryHookResult = ReturnType<typeof useServicesPageQuery>;
export type ServicesPageLazyQueryHookResult = ReturnType<typeof useServicesPageLazyQuery>;
export type ServicesPageSuspenseQueryHookResult = ReturnType<typeof useServicesPageSuspenseQuery>;
export type ServicesPageQueryResult = Apollo.QueryResult<ServicesPageQuery, ServicesPageQueryVariables>;
export const IndicatorAgreementDocument = gql`
    query IndicatorAgreement($ids: [Int!]) {
  dashboard {
    id
    authority
    validationResults
    keyIndicators(serviceIndicators: true) {
      _id
      departmentId
      serviceIndicators
      indicators(ids: $ids) {
        _id
        departmentId
        id
        code
        name
        approved
        approvedFromMc
        approvedFromOgv
        color
        valueFor2022
        valueFor2023
        valueFor2024
        valueFor2025
        valueInfo
        haveNested
        curators {
          name
          email
        }
        nestedIndicators {
          departmentId
          id
          code
          name
          color
          valueFor2022
          valueFor2023
          valueFor2024
          valueFor2025
          valueInfo
        }
        history {
          id
          approvedFromOgv
          changesDate
          executor
        }
      }
    }
  }
}
    `;

/**
 * __useIndicatorAgreementQuery__
 *
 * To run a query within a React component, call `useIndicatorAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicatorAgreementQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useIndicatorAgreementQuery(baseOptions?: Apollo.QueryHookOptions<IndicatorAgreementQuery, IndicatorAgreementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndicatorAgreementQuery, IndicatorAgreementQueryVariables>(IndicatorAgreementDocument, options);
      }
export function useIndicatorAgreementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndicatorAgreementQuery, IndicatorAgreementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndicatorAgreementQuery, IndicatorAgreementQueryVariables>(IndicatorAgreementDocument, options);
        }
export function useIndicatorAgreementSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IndicatorAgreementQuery, IndicatorAgreementQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IndicatorAgreementQuery, IndicatorAgreementQueryVariables>(IndicatorAgreementDocument, options);
        }
export type IndicatorAgreementQueryHookResult = ReturnType<typeof useIndicatorAgreementQuery>;
export type IndicatorAgreementLazyQueryHookResult = ReturnType<typeof useIndicatorAgreementLazyQuery>;
export type IndicatorAgreementSuspenseQueryHookResult = ReturnType<typeof useIndicatorAgreementSuspenseQuery>;
export type IndicatorAgreementQueryResult = Apollo.QueryResult<IndicatorAgreementQuery, IndicatorAgreementQueryVariables>;
export const ChangeDepartmentPositionDocument = gql`
    mutation ChangeDepartmentPosition($keyIndicatorId: Int!) {
  changeDepartmentPosition(keyIndicatorId: $keyIndicatorId) {
    id
    departmentId
  }
}
    `;
export type ChangeDepartmentPositionMutationFn = Apollo.MutationFunction<ChangeDepartmentPositionMutation, ChangeDepartmentPositionMutationVariables>;

/**
 * __useChangeDepartmentPositionMutation__
 *
 * To run a mutation, you first call `useChangeDepartmentPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDepartmentPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDepartmentPositionMutation, { data, loading, error }] = useChangeDepartmentPositionMutation({
 *   variables: {
 *      keyIndicatorId: // value for 'keyIndicatorId'
 *   },
 * });
 */
export function useChangeDepartmentPositionMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDepartmentPositionMutation, ChangeDepartmentPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeDepartmentPositionMutation, ChangeDepartmentPositionMutationVariables>(ChangeDepartmentPositionDocument, options);
      }
export type ChangeDepartmentPositionMutationHookResult = ReturnType<typeof useChangeDepartmentPositionMutation>;
export type ChangeDepartmentPositionMutationResult = Apollo.MutationResult<ChangeDepartmentPositionMutation>;
export type ChangeDepartmentPositionMutationOptions = Apollo.BaseMutationOptions<ChangeDepartmentPositionMutation, ChangeDepartmentPositionMutationVariables>;
export const IndicatorsAgreementDocument = gql`
    query IndicatorsAgreement {
  dashboard {
    id
    authority
    validationResults
    keyIndicators(serviceIndicators: true) {
      _id
      departmentId
      total
      key
      achievedOrApproved
      failedOrDisagreement
      serviceIndicators
      indicators {
        _id
        departmentId
        id
        name
        approved
        disagreements
        isKeyIndicator
        code
        mcName
        ogvName
      }
    }
  }
}
    `;

/**
 * __useIndicatorsAgreementQuery__
 *
 * To run a query within a React component, call `useIndicatorsAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndicatorsAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndicatorsAgreementQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndicatorsAgreementQuery(baseOptions?: Apollo.QueryHookOptions<IndicatorsAgreementQuery, IndicatorsAgreementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndicatorsAgreementQuery, IndicatorsAgreementQueryVariables>(IndicatorsAgreementDocument, options);
      }
export function useIndicatorsAgreementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndicatorsAgreementQuery, IndicatorsAgreementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndicatorsAgreementQuery, IndicatorsAgreementQueryVariables>(IndicatorsAgreementDocument, options);
        }
export function useIndicatorsAgreementSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IndicatorsAgreementQuery, IndicatorsAgreementQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IndicatorsAgreementQuery, IndicatorsAgreementQueryVariables>(IndicatorsAgreementDocument, options);
        }
export type IndicatorsAgreementQueryHookResult = ReturnType<typeof useIndicatorsAgreementQuery>;
export type IndicatorsAgreementLazyQueryHookResult = ReturnType<typeof useIndicatorsAgreementLazyQuery>;
export type IndicatorsAgreementSuspenseQueryHookResult = ReturnType<typeof useIndicatorsAgreementSuspenseQuery>;
export type IndicatorsAgreementQueryResult = Apollo.QueryResult<IndicatorsAgreementQuery, IndicatorsAgreementQueryVariables>;
export const SystemsPageDocument = gql`
    query SystemsPage {
  dashboard {
    id
    authority
    validationResults
    keySystems {
      id
      departmentId
      keyCount
      totalCount
      totalBudget
      systems {
        _id
        id
        name
        budgetTotal
        budgetDevelopment
        budgetExploitation
      }
    }
  }
}
    `;

/**
 * __useSystemsPageQuery__
 *
 * To run a query within a React component, call `useSystemsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemsPageQuery(baseOptions?: Apollo.QueryHookOptions<SystemsPageQuery, SystemsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemsPageQuery, SystemsPageQueryVariables>(SystemsPageDocument, options);
      }
export function useSystemsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemsPageQuery, SystemsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemsPageQuery, SystemsPageQueryVariables>(SystemsPageDocument, options);
        }
export function useSystemsPageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SystemsPageQuery, SystemsPageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemsPageQuery, SystemsPageQueryVariables>(SystemsPageDocument, options);
        }
export type SystemsPageQueryHookResult = ReturnType<typeof useSystemsPageQuery>;
export type SystemsPageLazyQueryHookResult = ReturnType<typeof useSystemsPageLazyQuery>;
export type SystemsPageSuspenseQueryHookResult = ReturnType<typeof useSystemsPageSuspenseQuery>;
export type SystemsPageQueryResult = Apollo.QueryResult<SystemsPageQuery, SystemsPageQueryVariables>;
export const AuthorityDocument = gql`
    query Authority {
  dashboard {
    id
    authority
    validationResults
    profile {
      id
      legacy
      accessToTagLibraryMaterials
      department {
        id
        authority
        departmentShortName
        departmentFullName
      }
      availableDepartments {
        authority
        departmentShortName
        departmentFullName
      }
    }
  }
}
    `;

/**
 * __useAuthorityQuery__
 *
 * To run a query within a React component, call `useAuthorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorityQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorityQuery(baseOptions?: Apollo.QueryHookOptions<AuthorityQuery, AuthorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorityQuery, AuthorityQueryVariables>(AuthorityDocument, options);
      }
export function useAuthorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorityQuery, AuthorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorityQuery, AuthorityQueryVariables>(AuthorityDocument, options);
        }
export function useAuthoritySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuthorityQuery, AuthorityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AuthorityQuery, AuthorityQueryVariables>(AuthorityDocument, options);
        }
export type AuthorityQueryHookResult = ReturnType<typeof useAuthorityQuery>;
export type AuthorityLazyQueryHookResult = ReturnType<typeof useAuthorityLazyQuery>;
export type AuthoritySuspenseQueryHookResult = ReturnType<typeof useAuthoritySuspenseQuery>;
export type AuthorityQueryResult = Apollo.QueryResult<AuthorityQuery, AuthorityQueryVariables>;